import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { CasesService } from '@app/services/cases.service';
import { CustomersService } from '@app/services/customers.service';
import { RolesService } from '@app/services/roles.service';

@Component({
  selector: 'app-super-tmk-case-list',
  templateUrl: './super-tmk-case-list.component.html',
  styleUrls: ['./super-tmk-case-list.component.scss']
})
export class SuperTmkCaseListComponent implements OnInit {

  Allcases;
  currentRole
  cases=[]
  case;
  form: FormGroup;
  toggleConfirmate= true;
  togglePosisionate= true;
  filter={
    state:'all'
  }

  constructor(
    private sCases: CasesService,
    private sRoles: RolesService,
    private sCustomer: CustomersService
  ) {

   }

   ngOnInit(): void {
   this.currentRole = this.sRoles.userRole
    this.showTmkCases()
  }

  showTmkCases() {
    this.sCases.getAllTmkCases().subscribe(res => {
      this.Allcases = res['cases'];
      this.cases = this.Allcases
    });
  }

  showInactiveCases(){

  this.cases=[]
  if(this.toggleConfirmate){
    for (let i = 0; i < this.Allcases.length; i++) {
      if (this.Allcases[i].isActive == false){
      this.cases.push(this.Allcases[i]);
      this.toggleConfirmate = false
      this.togglePosisionate = true

      }
    }
  }else{
    this.cases=this.Allcases
    this.toggleConfirmate = true
  }
  }

  ShowUnpositionateCases(){
    this.cases=[]
    if(this.togglePosisionate){
    for (let i = 0; i < this.Allcases.length; i++) {
      if (this.Allcases[i].commercial == null){
        this.cases.push(this.Allcases[i]);
this.togglePosisionate = false
this.toggleConfirmate = true

      }
    }
  }else{
    this.cases = this.Allcases
    this.togglePosisionate = true
  }

  }





}

import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { Router } from '@angular/router';
import { EditPieceComponent } from '@app/pieces/edit-piece/edit-piece.component';
import { ChoicesComponent } from '@app/pv/choices/choices.component';
import { CasesService } from '@app/services/cases.service';
import { HistoriqueService } from '@app/services/historique.service';
import { PiecesService } from '@app/services/pieces.service';
import { RolesService } from '@app/services/roles.service';

@Component({
  selector: 'app-products-head',
  templateUrl: './products-head.component.html',
  styleUrls: ['./products-head.component.scss']
})
export class ProductsHeadComponent implements OnInit {

@Input() case;
@Input() chantierId
  tabs;
  currentKit = {
    nombre: '',
    puissance: '',
    orientation: '',
    capacite: '',
    unite: '',
    pose: '',
    marque: '',
    monotri: '',
    monitoring: '',
    options_bt: [],
    options_pac: [],
    type_pac: '',
    type_iso : '',
    options: [],
    name : '',
    surface: '',
    modules : [],
    marque_type: '',
    type: '',
    fixation: '',
    nbr_bouche_extraction : '',
    nbr_bouche_soufflage : '',
    volume : '',
    local_pose : '',
    hors_gel : '',
    marque_type_ue : '',
    local_pose_ui : '',
    type_pose_ui : '',
    declivite: '',
    onduleur: '',
    marque_type_onduleur: '',
    n_serie_onduleur: '',
    nbr_onduleur: '',
    valid: null,
    type_bt: '',
    local_pose_ue: '',
    marque_type_ui: '',
    nbr_split: '',
    nbr_bouche_insuflation: '',
    nbr_bouche_reprise: '',
    type_pose_ue: '',
  }

  v2 = false

  empForm:FormGroup;
  add = false
  techs = ["azert","cdcd","jhg","poiuy","awaw",]
  poses = ["au sol","sur chassis"]
  capacities = ['200 L','250 L', '270 L']
  fixations = ["Sur sol","Sur mur"]
  orientations = ["Portrait","Paysage"]
  poses_pv = ["Sol","Façade","Intégration","Sur imposition"]
  types = ["onduleur","micro onduleur"]

  modules = []
  bouches_extraction = []
  bouches_soufflage = []
  bouches_insuflation = []
  bouches_reprise = []

  splits = []
  currentKitValid = false

  multipleImagesPieces
  n_serie = false
  n_serie_onduleur = false
  n_serie_ue_bt = false
  n_serie_bt = false
  n_serie_vmc = false
  n_serie_pacae_ue = false
  n_serie_pacae_ui = false
  n_serie_pacaa_ue = false
  n_serie_pacaa_ui = false
  n_serie_pv = false
  n_serie_adoucisseur = false


  piece_serie:any;
  piece_serie_onduleur:any;
  piece_serie_bt: any;
  piece_serie_ue_bt: any;
  piece_serie_vmc: any;

  piece_serie_pacae_ue
  piece_serie_pacae_ui
  piece_serie_pacaa_ue
  piece_serie_pacaa_ui
  piece_serie_pv

  piece_serie_adoucisseur

  constructor(
    private sCases: CasesService,
    private sb:MatSnackBar,
    private sHistoriques: HistoriqueService,
    private sRoles: RolesService,
    private dialog: MatDialog,
    private sPieces: PiecesService,
    private router: Router
  ) {   }

  ngOnInit(): void {

    if(!this.case.kits_vendus.startsWith('['))
      this.sb.open(`Impossible d'effectuer des PV dématérialisés pour ce dossier`, '', { duration: 5000, horizontalPosition: 'center', panelClass: ['delete-snackbar'] });

    else {
      this.v2 = true
      this.tabs = JSON.parse(this.case.kits_vendus).filter(k => k.active).sort(function compare(a, b) {
        if (a.name > b.name)
           return -1;
        if (a.name < b.name )
           return 1;
        return 0;
      })

      const kit = JSON.parse(this.case.kits_vendus).filter(r => r.active).sort(function compare(a, b) {
        if (a.name > b.name)
           return -1;
        if (a.name < b.name )
           return 1;
        return 0;
      })
      const index = kit.findIndex(item => item.name === kit[0].name);
      const selected = kit[index]
      this.currentKit = selected
      if(selected.name === 'Ballon thermodynamique') {
        if(selected.type_bt === undefined) {
          this.openDialog()
        }
      }
      this.checkCurrentKitValid()
      this.checkPieceOfCible()

      if(this.currentKit.type === 'VMC Double Flux') {

        if(this.currentKit.marque_type != undefined && this.currentKit.marque_type != '' &&
           this.currentKit.fixation != undefined && this.currentKit.fixation != '' &&
           this.currentKit.local_pose != undefined && this.currentKit.local_pose != '' &&
           this.currentKit.nbr_bouche_extraction != undefined && this.currentKit.nbr_bouche_extraction != '' &&
           this.currentKit.nbr_bouche_soufflage != undefined && this.currentKit.nbr_bouche_soufflage != '') {
            this.currentKitValid = true
          this.getCurrentKitAndSaveChange()

        }
        else {
          this.currentKitValid = false
          this.getCurrentKitAndSaveChange()
        }

      }
    }
  }

  openDialog(): void {
    const dialogRef = this.dialog.open(EditPieceComponent, {
      width: '65%',
      panelClass: 'custom-dialog-container',
      data: {
        case: this.case,
        currentKit: this.currentKit,
        chantierId: this.chantierId._id,
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      const array = JSON.parse(this.case.kits_vendus);
      const index = array.findIndex(item => item.name === "Ballon thermodynamique");
      const selected = array[index]
      this.currentKit = selected
    });
  }

  openDialogChoice(): void {
    localStorage.setItem('currentKit', JSON.stringify(this.currentKit));
    localStorage.setItem('case', JSON.stringify(this.case));
    localStorage.setItem('chantierId', JSON.stringify(this.chantierId));

    const dialogRef = this.dialog.open(ChoicesComponent, {
      width: '85%',
      panelClass: 'custom-dialog-container',
      data: {
        case: this.case,
        currentKit: this.currentKit,
        chantierId: this.chantierId,
      }
    });

  }

  _value: number = 0;
  _step: number = 1;
  _min: number = 0;

  @Input('value')
  set inputValue(_value: number) {
    this._value = this.parseNumber(_value);
  }

  @Input()
  set step(_step: number) {
    this._step = this.parseNumber(_step);
  }

  @Input()
  set min(_min: number) {
    this._min = this.parseNumber(_min);
  }

  private parseNumber(num: any): number {
    return +num;
  }

  incrementValue(step: number = 1, key, name,reason, option,product, value): void {
    if(this.currentKit[key] === null || this.currentKit[key] === undefined) {
      this.currentKit[key] = 0
    }
    this.currentKit[key] = this.currentKit[key] + step
    const newValue = this.currentKit[key]

    this.addOption(name,reason, option,product, newValue)
  }



  incrementValuePv(step: number = 1, key, name,reason, option,product, value): void {
    if(this.currentKit[key] === null || this.currentKit[key] === undefined) {
      this.currentKit[key] = 0
    }
    this.currentKit[key] = this.currentKit[key] + step
    const newValue = this.currentKit[key]

    this.addOption(name,reason, option,product, newValue)
    this.addModulesPv()
  }

  addModulesPv() {

    const array = JSON.parse(this.case.kits_vendus);
    const index = array.findIndex(item => item.name === this.currentKit.name);
    const selected = array[index]
    this.currentKit = selected
/*     selected['modules'] = []
    selected['onduleurs'] = [] */

    this.case.kits_vendus = JSON.stringify(array)

    this.sCases.editCase(this.case, this.case._id)
  }

  addBoucheExtraction() {
    this.bouches_extraction = []
    for (let index = 0; index < parseInt(this.currentKit.nbr_bouche_extraction); index++) {
      const key = 'Bouche n° ' + (index + 1)
      this.bouches_extraction.push({'name' : key});
    }
    const array = JSON.parse(this.case.kits_vendus);
    const index = array.findIndex(item => item.name === this.currentKit.name);
    const selected = array[index]
    this.currentKit = selected
    selected['bouches_extraction'] = this.bouches_extraction
    this.case.kits_vendus = JSON.stringify(array)

    this.sCases.editCase(this.case, this.case._id)
  }

  incrementValueBouchesExtractionVmc(step: number = 1, key, name,reason, option,product, value): void {
    if(this.currentKit[key] === null || this.currentKit[key] === undefined) {
      this.currentKit[key] = 0
    }
    this.currentKit[key] = this.currentKit[key] + step

    const newValue = this.currentKit[key]

    this.addOption(name,reason, option,product, newValue)
    this.addBoucheExtraction()
  }

  addBoucheSoufflage() {
    this.bouches_soufflage = []
    for (let index = 0; index < parseInt(this.currentKit.nbr_bouche_soufflage); index++) {
      const key = 'Bouche n° ' + (index + 1)
      this.bouches_soufflage.push({'name' : key});
    }
    const array = JSON.parse(this.case.kits_vendus);
    const index = array.findIndex(item => item.name === this.currentKit.name);
    const selected = array[index]
    this.currentKit = selected
    selected['bouches_soufflage'] = this.bouches_soufflage
    this.case.kits_vendus = JSON.stringify(array)

    this.sCases.editCase(this.case, this.case._id)
  }
  addBoucheInsuflation() {
    this.bouches_insuflation = []
    for (let index = 0; index < parseInt(this.currentKit.nbr_bouche_insuflation); index++) {
      const key = 'Bouche n° ' + (index + 1)
      this.bouches_insuflation.push({'name' : key});
    }
    const array = JSON.parse(this.case.kits_vendus);
    const index = array.findIndex(item => item.name === this.currentKit.name);
    const selected = array[index]
    this.currentKit = selected
    selected['bouches_insuflation'] = this.bouches_insuflation
    this.case.kits_vendus = JSON.stringify(array)

    this.sCases.editCase(this.case, this.case._id)
  }
  addBoucheReprise() {
    this.bouches_reprise = []
    for (let index = 0; index < parseInt(this.currentKit.nbr_bouche_reprise); index++) {
      const key = 'Bouche de reprise n° ' + (index + 1)
      this.bouches_reprise.push({'name' : key});
    }
    const array = JSON.parse(this.case.kits_vendus);
    const index = array.findIndex(item => item.name === this.currentKit.name);
    const selected = array[index]
    this.currentKit = selected
    selected['bouches_reprise'] = this.bouches_reprise
    this.case.kits_vendus = JSON.stringify(array)

    this.sCases.editCase(this.case, this.case._id)
  }

  addSplitUiPac() {
    this.splits = []
    for (let index = 0; index < parseInt(this.currentKit.nbr_split); index++) {
      const key = 'Split n° ' + (index + 1)
      this.splits.push({'name' : key});
    }
    const array = JSON.parse(this.case.kits_vendus);
    const index = array.findIndex(item => item.name === this.currentKit.name);
    const selected = array[index]
    this.currentKit = selected
    selected['splits'] = this.splits
    this.case.kits_vendus = JSON.stringify(array)

    this.sCases.editCase(this.case, this.case._id)
  }

  incrementValueBouchesSoufflageVmc(step: number = 1, key, name,reason, option,product, value): void {
    if(this.currentKit[key] === null || this.currentKit[key] === undefined) {
      this.currentKit[key] = 0
    }
    this.currentKit[key] = this.currentKit[key] + step
    const newValue = this.currentKit[key]


    this.addOption(name,reason, option,product, newValue)
    this.addBoucheSoufflage()
  }

  incrementSplitUiPac(step: number = 1, key, name,reason, option,product, value): void {
    if(this.currentKit[key] === null || this.currentKit[key] === undefined) {
      this.currentKit[key] = 0
    }
    this.currentKit[key] = this.currentKit[key] + step

    const newValue = this.currentKit[key]

    this.addOption(name,reason, option,product, newValue)
    this.addSplitUiPac()
  }

  incrementBoucheInsuflation(step: number = 1, key, name,reason, option,product, value): void {
    if(this.currentKit[key] === null || this.currentKit[key] === undefined) {
      this.currentKit[key] = 0
    }
    this.currentKit[key] = this.currentKit[key] + step

    const newValue = this.currentKit[key]

    this.addOption(name,reason, option,product, newValue)
    this.addBoucheInsuflation()
  }
  incrementBoucheReprise(step: number = 1, key, name,reason, option,product, value): void {
    if(this.currentKit[key] === null || this.currentKit[key] === undefined) {
      this.currentKit[key] = 0
    }
    this.currentKit[key] = this.currentKit[key] + step

    const newValue = this.currentKit[key]

    this.addOption(name,reason, option,product, newValue)
    this.addBoucheReprise()
  }



  changeCurrentKit(changeEvent: MatTabChangeEvent) {
    const array = JSON.parse(this.case.kits_vendus);
    const index = array.findIndex(item => item.name === changeEvent.tab.textLabel);
    const selected = array[index]
    this.currentKit = selected
    if(changeEvent.tab.textLabel === 'Ballon thermodynamique') {
      if(selected.type_bt === undefined) {
        this.openDialog()
      }
    }
    this.checkPieceOfCible()
    this.checkCurrentKitValid()
  }


    addOption(name,reason, option,product, value) {

    let find = JSON.parse(this.case.kits_vendus).find(x => x.name === name);
    find[option] = value;

    const array = JSON.parse(this.case.kits_vendus);
    const index = array.findIndex(item => item.name === name);
    const selected = array[index]
    this.currentKit = selected
    selected[option] = value

    this.case.kits_vendus = JSON.stringify(array)

    this.sCases.editCase(this.case, this.case._id)
    this.sHistoriques.createHistorique(this.case._id, this.case.customer._id, reason, (option + ' ' + product),value,this.sRoles.user.id).then(res => {
      if (res['error']) {
        alert(res);
      }
    });
    this.checkCurrentKitValid()

  }

  getCurrentKitAndSaveChange() {
    let find = JSON.parse(this.case.kits_vendus).find(x => x.name === this.currentKit.type);
    find['valid'] = true;
    const array = JSON.parse(this.case.kits_vendus);
    const index = array.findIndex(item => item.name === this.currentKit.type);
    const selected = array[index]
    this.currentKit = selected

    if(this.currentKitValid)
      selected.valid = true
    else {
      selected.valid = false

    }
    this.case.kits_vendus = JSON.stringify(array)
    this.sCases.editCase(this.case, this.case._id)
  }


  checkCurrentKitValid() {
    if(this.currentKit.type === 'VMC Double Flux') {

      if(this.currentKit.marque_type != undefined && this.currentKit.marque_type != '' &&
         this.currentKit.fixation != undefined && this.currentKit.fixation != '' &&
         this.currentKit.local_pose != undefined && this.currentKit.local_pose != '' &&
         this.currentKit.nbr_bouche_extraction != undefined && this.currentKit.nbr_bouche_extraction != '' &&
         this.currentKit.nbr_bouche_soufflage != undefined && this.currentKit.nbr_bouche_soufflage != '') {
          this.currentKitValid = true
        this.getCurrentKitAndSaveChange()

      }
      else {
        this.currentKitValid = false
        this.getCurrentKitAndSaveChange()
      }

    }
    if(this.currentKit.type === 'Panneaux solaires') {
      if(this.currentKit.marque_type != undefined && this.currentKit.marque_type != '' &&
      this.currentKit.nombre != undefined && this.currentKit.nombre != '' &&
      this.currentKit.pose != undefined && this.currentKit.pose != '' &&
      this.currentKit.orientation != undefined && this.currentKit.orientation != '' &&
      this.currentKit.declivite != undefined && this.currentKit.declivite != '' &&
      this.currentKit.onduleur != undefined && this.currentKit.onduleur != '' &&
      this.currentKit.marque_type_onduleur != undefined && this.currentKit.marque_type_onduleur != '' &&
      this.currentKit.nbr_onduleur != undefined && this.currentKit.nbr_onduleur != '') {
       this.currentKitValid = true
       this.getCurrentKitAndSaveChange()
      }
      else {
        this.currentKitValid = false
        this.getCurrentKitAndSaveChange()
      }
    }
    if(this.currentKit.type === 'Isolation') {
      if(this.currentKit.type_iso != undefined && this.currentKit.type_iso != '' &&
      this.currentKit.surface != undefined && this.currentKit.surface != '') {
       this.currentKitValid = true
       this.getCurrentKitAndSaveChange()
      }
      else {
        this.currentKitValid = false
        this.getCurrentKitAndSaveChange()
      }
    }
    if(this.currentKit.type === 'Mise aux normes du tableau électrique') {
       this.currentKitValid = true
       this.getCurrentKitAndSaveChange()
    }

    if(this.currentKit.type === 'Ballon thermodynamique') {
      if(this.currentKit.type_bt === 'Split') {
        if(this.currentKit.capacite != undefined && this.currentKit.capacite != '' &&
        this.currentKit.marque_type != undefined && this.currentKit.marque_type != '' &&
        this.currentKit.local_pose != undefined && this.currentKit.local_pose != '' &&
        this.currentKit.hors_gel != undefined && this.currentKit.hors_gel != '' &&
        this.currentKit.type_pose_ue != undefined && this.currentKit.type_pose_ue != ''
        ) {
         this.currentKitValid = true
         this.getCurrentKitAndSaveChange()
        }
        else {
          this.currentKitValid = false
          this.getCurrentKitAndSaveChange()
        }
      }
      else {
          if(this.currentKit.capacite != undefined && this.currentKit.capacite != '' &&
          this.currentKit.marque_type != undefined && this.currentKit.marque_type != '' &&
          this.currentKit.local_pose != undefined && this.currentKit.local_pose != '' &&
          this.currentKit.hors_gel != undefined && this.currentKit.hors_gel != ''
          ) {
           this.currentKitValid = true
           this.getCurrentKitAndSaveChange()
          }
          else {
            this.currentKitValid = false
            this.getCurrentKitAndSaveChange()
          }
      }


    }
    if(this.currentKit.type === 'Adoucisseur d\'eau') {
      if(this.currentKit.marque_type != undefined && this.currentKit.marque_type != '') {
       this.currentKitValid = true
       this.getCurrentKitAndSaveChange()
      }
      else {
        this.currentKitValid = false
        this.getCurrentKitAndSaveChange()
      }
    }

    if(this.currentKit.type === 'PAC Air/Air') {
      if(this.currentKit.type_pac === "Split") {
        if(this.currentKit.marque_type_ue != undefined && this.currentKit.marque_type_ue != '' &&
        this.currentKit.type_pose_ue != undefined && this.currentKit.type_pose_ue != '' &&
        this.currentKit.marque_type_ui != undefined && this.currentKit.marque_type_ui &&
        this.currentKit.nbr_split != undefined && this.currentKit.nbr_split) {
         this.currentKitValid = true
         this.getCurrentKitAndSaveChange()

        }
        else {
          this.currentKitValid = false
          this.getCurrentKitAndSaveChange()
        }
      }

      else if(this.currentKit.type_pac === "Gainable") {
        if(this.currentKit.marque_type_ue != undefined && this.currentKit.marque_type_ue != '' &&
        this.currentKit.marque_type_ui != undefined && this.currentKit.marque_type_ui &&
        this.currentKit.type_pose_ue != undefined && this.currentKit.type_pose_ue &&
        this.currentKit.local_pose_ui != undefined && this.currentKit.local_pose_ui &&
        this.currentKit.nbr_bouche_insuflation != undefined && this.currentKit.nbr_bouche_insuflation &&
        this.currentKit.nbr_bouche_reprise != undefined && this.currentKit.nbr_bouche_reprise) {
         this.currentKitValid = true
         this.getCurrentKitAndSaveChange()
        }
        else {
          this.currentKitValid = false
          this.getCurrentKitAndSaveChange()
        }
      }
    }
    if(this.currentKit.type === 'PAC Air/Eau') {
      if(this.currentKit.marque_type_ui != undefined && this.currentKit.marque_type_ui != '' &&
      this.currentKit.marque_type_ue != undefined && this.currentKit.marque_type_ue != '' &&
      this.currentKit.local_pose != undefined && this.currentKit.local_pose != '' &&
      this.currentKit.pose != undefined && this.currentKit.pose != '') {
       this.currentKitValid = true
       this.getCurrentKitAndSaveChange()
      }
      else {
        this.currentKitValid = false
        this.getCurrentKitAndSaveChange()
      }
    }
  }

  createDoc() {
    this.openDialogChoice()
  }

  createDocBI() {
    this.router.navigate(['bi-visualize',this.case._id],{ state: { chantierId: this.chantierId } });
  }

  setTypeBtEmpty() {

    let find = JSON.parse(this.case.kits_vendus).find(x => x.name === 'Ballon thermodynamique');
    find['type_bt'] = undefined;

    const array = JSON.parse(this.case.kits_vendus);
    const index = array.findIndex(item => item.name === 'Ballon thermodynamique');
    const selected = array[index]
    this.currentKit = selected
    selected['type_bt'] = undefined

    this.case.kits_vendus = JSON.stringify(array)

    this.sCases.editCase(this.case, this.case._id)
  }

  selectMultiple(event, title:string, n_serie:string) {
    if (event.target.files.length > 0) {
      this.multipleImagesPieces = event.target.files;
    }
    this.onSubmit(title, n_serie)
  }

  onSubmit(title:string,n_serie:string) {
    const form = {
      title: title,
      qualification: 'Conforme',
      caseId: this.case._id,
      created_at: new Date(),
      type: 'technicien',
      chantier: this.chantierId._id,
      cible: n_serie,
      description: '',
    }
    this.sPieces.addTechPiece(form, this.multipleImagesPieces, this.case._id, this.chantierId._id)
    setTimeout(() => {
      this.checkPieceOfCible()
    }, 1100);
  }

  checkPieceOfCible() {
    this.sPieces.getPiecesByChantierId(this.chantierId._id).subscribe(res => {
      this.piece_serie= res
      this.piece_serie_onduleur = res

      if(this.piece_serie.find(e => e.title === 'N° de serie adoucisseur') && this.currentKit.type === 'Adoucisseur d\'eau') {
        this.piece_serie_adoucisseur = this.piece_serie.filter(e => e.title === 'N° de serie adoucisseur')
        this.n_serie_adoucisseur = true
      }
      if(this.piece_serie.find(e => e.title === 'N° de serie vmc') && this.currentKit.type === 'VMC Double Flux') {
        this.piece_serie_vmc = this.piece_serie.filter(e => e.title === 'N° de serie vmc')
        this.n_serie_vmc = true
      }
      if(this.piece_serie.find(e => e.title === 'N° de serie panneaux solaires') && this.currentKit.type === 'Panneaux solaires') {
        this.piece_serie_pv = this.piece_serie.filter(e => e.title === 'N° de serie panneaux solaires')
        this.n_serie_pv = true
      }
      if(this.piece_serie_onduleur.find(e => e.title === 'N° de serie onduleurs') && this.currentKit.type === 'Panneaux solaires') {
        this.piece_serie_onduleur = this.piece_serie_onduleur.filter(e => e.title === 'N° de serie onduleurs')
        this.n_serie_onduleur = true
      }
      if(this.piece_serie.find(e => e.title === 'N° de serie ballon thermodynamique') && this.currentKit.type === 'Ballon thermodynamique') {
        this.piece_serie_bt = this.piece_serie.filter(e => e.title === 'N° de serie ballon thermodynamique')
        this.n_serie_bt = true
      }
      if(this.piece_serie.find(e => e.title === 'N° de serie UE ballon thermodynamique') && this.currentKit.type === 'Ballon thermodynamique') {
        this.piece_serie_ue_bt = this.piece_serie.filter(e => e.title === 'N° de serie UE ballon thermodynamique')
        this.n_serie_ue_bt = true
      }
      if(this.piece_serie.find(e => e.title === 'N° de serie UE PAC Air Eau') && this.currentKit.type === 'PAC Air/Eau') {
        this.piece_serie_pacae_ue = this.piece_serie.filter(e => e.title === 'N° de serie UE PAC Air Eau')
        this.n_serie_pacae_ue = true
      }
      if(this.piece_serie.find(e => e.title === 'N° de serie UI PAC Air Eau') && this.currentKit.type === 'PAC Air/Eau') {
        this.piece_serie_pacae_ui = this.piece_serie.filter(e => e.title === 'N° de serie UI PAC Air Eau')
        this.n_serie_pacae_ui = true
      }
      if(this.piece_serie.find(e => e.title === 'N° de serie UE PAC Air Air') && this.currentKit.type === 'PAC Air/Air') {
        this.piece_serie_pacaa_ue = this.piece_serie.filter(e => e.title === 'N° de serie UE PAC Air Air')
        this.n_serie_pacaa_ue = true
      }
      if(this.piece_serie.find(e => e.title === 'N° de serie UI PAC Air Air') && this.currentKit.type === 'PAC Air/Air') {
        this.piece_serie_pacaa_ui = this.piece_serie.filter(e => e.title === 'N° de serie UI PAC Air Air')
        this.n_serie_pacaa_ui = true
      }


      else {
        this.n_serie = false
      }


    })

  }

}

import { Component, Input, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { CasesService } from '@app/services/cases.service';
import { RdvAddComponent } from '@app/rdv/rdv-add/rdv-add.component';
import { RolesService } from '@app/services/roles.service';
import { PiecesService } from '@app/services/pieces.service';
import { HistoriqueService } from '@app/services/historique.service';
import { TaskCaseListComponent } from '@app/tasks/task-case-list/task-case-list.component';
import { CreateTaskComponent } from '@app/tasks/create-task/create-task.component';
import { Observable, Subscription } from 'rxjs';
import { CaseProductsComponent } from '@app/case/case-products/case-products.component';

@Component({
  selector: 'app-commercial-case-detail',
  templateUrl: './commercial-case-detail.component.html',
  styleUrls: ['./commercial-case-detail.component.scss']
})
export class CommercialCaseDetailComponent implements OnInit {
  @Input() statut
  @Input() fid

  detail = true;

  userRole;
  caseId: string;

  case;
  caseForm: FormGroup;
  formName: FormGroup;

  rdvForm: FormGroup;
  arrayRdvs = [];
  state;
  readOnly = false;
  curTab: any = 0;
  mobile;
  pieces;
  piecesManquantes;

  disableTextbox;
  currentlyDisabled;
  @Input() events: Observable<void>;
  eventsSubscription: Subscription;

  selectedProducts;
  autres = []
  products = []
  v1
  v2


  ngOnInit() {
    this.eventsSubscription = this.events.subscribe(() => this.openDialog());

    if(this.statut === 'Signée') {
      this.disableTextbox = true;
    }
    if(this.statut === 'Signée') {
      this.currentlyDisabled = 'two';
    }
    if (window.screen.width <= 600) {
      this.mobile = true;
    }


    this.showPieces();
    this.showPiecesManquantes()
    this.fid ? this.openDialog() : ''

    this.showCaseById().then(() => {
      this.checkAndDisablePriceFields();
    });
   }

  constructor(
    private route: ActivatedRoute,
    private sCases: CasesService,
    private sRoles: RolesService,
    private fb: FormBuilder,
    private dialog: MatDialog,
    private router: Router,
    private sPieces: PiecesService,
    private sHistoriques: HistoriqueService,
    ){
      this.caseId = this.route.snapshot.params['id'];
      this.userRole = this.sRoles.userRole;
      this.showCaseById().then(res => {
        this.arrayRdvs = this.case.rdvs;
        this.state = this.case.state;

        if(this.case.kits_vendus != undefined) {

          if(this.case.kits_vendus.startsWith('['))
            this.v2 = true
          else
            this.v1 = true
          if(this.case.kits_vendus != null && this.case.kits_vendus.startsWith('[')) {
            JSON.parse(this.case.kits_vendus).forEach(element => {
              if(element.active) {
                this.products.push(element.name)
              }
            });

            /////////////////////////////////////
            const array1 = this.products;

            const parseKits = JSON.parse(this.case.kits_vendus)

            parseKits.forEach(element => {
            // si un produit du kit est dans le tableau selectionner
            if(array1.includes(element.name)) {
              // on vient éditer l'object associé dans products en active true
              let result = parseKits.filter(e => e.name === element.name)[0]
              if(!result.active) {
                parseKits.filter(e => e.name === element.name)[0].active = true
              }
            }
            else {
              parseKits.filter(e => e.name === element.name)[0].active = false
            }
            });
            this.selectedProducts = JSON.stringify(parseKits)
            this.selectedProducts = JSON.parse(this.selectedProducts).filter(res => res.active)
            this.products.forEach(element => {
              if(element != 'Adoucisseur d`\'eau' &&
                  element != 'Ballon thermodynamique' &&
                  element != 'Batterie' &&
                  element != 'Mise aux normes du tableau électrique' &&
                  element != 'PAC Air/Air' &&
                  element != 'PAC Air/Eau' &&
                  element != 'Panneaux solaires' &&
                  element != 'Thermostat radio' &&
                  element != 'Isolation' &&
                  element != 'VMC Double Flux') {
                    this.autres.push(element)
                  }
            })
            ////////////////////////////////////
          }
          else {
            this.products === this.case.kits_vendus
          }

          }
          else {
            this.v1 = true
          }
        this.caseForm = this.fb.group({
          r1: this.fb.group({
            'debrief': [this.case.r1[0].debrief],
            'date': [this.case.r1[0].date],
          }),
          'kits_vendus': [this.products],
          "financement_type": [null],
          "price_ht": [null],
          "price_ttc": [null],
          "description": [null],
        });
        this.rdvForm = this.fb.group({
          rdvs: this.fb.array([]),
        });
      });
    }

    currTab(event) {
      this.curTab = event.index;
    }

  rdvs(): FormArray {
    return this.rdvForm.get("rdvs") as FormArray
  }

  combineArrays() {
    const arrayCombine = this.arrayRdvs.concat(this.rdvForm.value.rdvs);
    this.saveRdvs(arrayCombine);
  }

  showCaseById() {
    return new Promise(resolve => {

    this.sCases.getCaseById(this.caseId).subscribe(res => {
      this.case = res[0];
      this.arrayRdvs = this.case.rdvs;
      resolve(res);
        }, error => {
          console.log(error);
      });
    });
  }

  saveChange(reason, change, value) {
    this.sHistoriques.createHistorique(this.caseId, this.case.customer._id, reason, change,value,this.sRoles.user.id).then(res => {
      if (res['error']) {
        alert(res);
      }
    });
    const productsName = []
    if(this.selectedProducts && this.selectedProducts.length != 0) {
      this.selectedProducts.forEach(element => {
        if(element.active) {
          productsName.push(element.name)
        }
      });
      this.saveChangeKit(productsName,reason,change,value)

    }
    else {
      this.caseForm.value.kits_vendus = undefined
      this.sCases.editCase(this.caseForm.value, this.caseId).then(res => {
        if(res['error']) {
          return res.error;
        }
        else {
          this.showCaseById().then(() => {
            this.checkAndDisablePriceFields();
          });
        }
      });
    }

    if (change === 'prix HT' && this.caseForm.value.price_ht > 0) {
      this.caseForm.controls['price_ht'].disable();
    }
    if (change === 'prix TTC' && this.caseForm.value.price_ttc > 0) {
      this.caseForm.controls['price_ttc'].disable();
    }

  }

  saveChangeKit(event,reason,change,value) {
    const array1 = event;
    const parseKits = JSON.parse(this.case.kits_vendus)

    parseKits.forEach(element => {
    // si un produit du kit est dans le tableau selectionner
    if(array1.includes(element.name)) {
      // on vient éditer l'object associé dans products en active true
      let result = parseKits.filter(e => e.name === element.name)[0]
      if(!result.active) {
        parseKits.filter(e => e.name === element.name)[0].active = true
      }
    }
    else {
      parseKits.filter(e => e.name === element.name)[0].active = false
    }
    });
    this.caseForm.value.kits_vendus = JSON.stringify(parseKits)
    this.selectedProducts = JSON.stringify(parseKits)
    this.selectedProducts = JSON.parse(this.selectedProducts).filter(res => res.active)
    this.products.forEach(element => {
      if(element != 'Adoucisseur d`\'eau' &&
          element != 'Ballon thermodynamique' &&
          element != 'Batterie' &&
          element != 'Mise aux normes du tableau électrique' &&
          element != 'PAC Air/Air' &&
          element != 'PAC Air/Eau' &&
          element != 'Panneaux solaires' &&
          element != 'Thermostat radio' &&
          element != 'Isolation' &&
          element != 'VMC Double Flux') {
            this.autres.push(element)
          }
    })
    this.sHistoriques.createHistorique(this.caseId, this.case.customer._id, reason, change,JSON.stringify(value),this.sRoles.user.id).then(res => {
      if (res['error']) {
        alert(res);
      }
    });

     this.sCases.editCase(this.caseForm.value, this.caseId).then(res => {
      if(res['error']) {
        return res.error;
      }
      else {
        this.showCaseById();

      }
    });
  }

  saveRdvs(arrayRdvs) {
    this.sCases.addRdv(arrayRdvs, this.caseId).then(res => {
      if(res['error']) {
        return res.error;
      }
    });
    this.showCaseById();
  }

  deleteOneRdv(arrayRdvs) {
    if ( confirm( "Voulez vous supprimer votre rendez vous ?" ) ) {

      this.sCases.deleteRdv(arrayRdvs, this.caseId).then(res => {
        if (res['error']) {
          return res.error;
        }
        this.showCaseById();
      });
    }

  }

  openDialog(): void {
    const dialogRef = this.dialog.open(RdvAddComponent, {
      width: '640px',
      disableClose: true,
      data: {
        arrayRdvs: this.arrayRdvs,
        caseId: this.caseId,
        state: this.state,
      }
    });
    dialogRef.afterClosed().subscribe(() => {
      this.showCaseById();
    });
  }

  editRdvs(reason, change, value) {
    this.sCases.editCase(this.case, this.caseId).then(res => {
      if(res['error']) {
        return res.error;
      }
      this.showCaseById();
    });
    this.sHistoriques.createHistorique(this.caseId, this.case.customer._id, reason, change,value,this.sRoles.user.id).then(res => {
      if (res['error']) {
        alert(res);
      }
    });
  }

  goToEditFinancement(currentPiece) {
    if (this.userRole === 'Adv' || this.userRole === 'Admin') {
      this.router.onSameUrlNavigation = 'reload';
      this.router.navigate(['case-template', this.caseId], { state: { value: 'pieceEdit', currentPiece: currentPiece }  });
    }
    else if (this.userRole === 'Commercial' || this.userRole === 'Responsable d\'agence') {
      this.router.onSameUrlNavigation = 'reload';
      this.router.navigate(['commercial-template', this.caseId], { state: { value: 'pieceEdit', currentPiece: currentPiece, mobileChoice: true }  });
    }
  }

  showPieces() {
    this.sPieces.getPiecesByCaseId(this.caseId).subscribe(res => {
      this.pieces = res;
    });
  }
  showPiecesManquantes() {
    this.sPieces.getPiecesManquantesByCaseId(this.caseId).subscribe(res => {
      this.piecesManquantes = res;
    });
  }

  showTaskDialog(): void {
    const dialogRef = this.dialog.open(TaskCaseListComponent, {
      width: '40%',
      height:'80%',
      data :{
             caseId: this.caseId,
      }
    });
    dialogRef.afterClosed().subscribe(result => {
    });
  }

  createTaskDialog(): void {
    const dialogRef = this.dialog.open(CreateTaskComponent, {
      width: '40%',
      height:'80%',
      data :{
             caseId: this.caseId,
             inCase: true,
             edit: false,
      }
    });
    dialogRef.afterClosed().subscribe(result => {
    });
  }

  openKitVendu(): void {
    const dialogRef = this.dialog.open(CaseProductsComponent, {
      width: '640px',
      disableClose: true,
      data: {
        currentCase: this.case,
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      //this.saveChangeKit(this.products)
      this.selectedProducts = this.case.kits_vendus

      this.selectedProducts = JSON.parse(this.selectedProducts).filter(res => res.active)
    });
  }

  checkAndDisablePriceFields() {
    if (this.case && this.case.price_ht > 0) {
      this.caseForm.controls['price_ht'].disable();
    }
    if (this.case && this.case.price_ttc > 0) {
      this.caseForm.controls['price_ttc'].disable();
    }
  }

}



<div class="bottombar" *ngIf="user">
  <p *ngIf="this.sRoles.userRole === 'Responsable Technicien' || this.sRoles.userRole === 'Technicien'" class="compteur-bottom">
    <span *ngIf="countUnread && countUnread.count > 0" matBadge="{{ countUnread.count }}" matBadgeSize="small"></span>
  </p>
  <p class="compteur-taches-bottom">
    <span *ngIf="tasks" matBadge="{{ tasks.length }}" matBadgeSize="small"></span>
  </p>
  <a class="nav-item nav-link"  style="font-size:0.8rem;" routerLinkActive="active" (click)="goHome()">
    <img *ngIf="router.url != '/commercial-dashboard' " src="../../assets/bar-icons/accueil.svg">
    <img *ngIf="router.url == '/commercial-dashboard' " src="../../assets/bar-icons/accueil-active.svg">
  </a>
  <a class="nav-item nav-link"  style="font-size:0.8rem;" routerLinkActive="active" (click)="goToTasks()" *ngIf="isAdmin || isCommercial || isRespCommercial || isTechnicien||isResponsableTech ">
    <img *ngIf="router.url != '/task-list' " src="../../assets/bar-icons/taches.svg">
    <img *ngIf="router.url == '/task-list' " src="../../assets/bar-icons/taches-active-mobile.svg">
  </a>
  <a class="nav-item nav-link case-button"  style="font-size:0.8rem;" routerLinkActive="active" (click)="goToCaseList()" *ngIf=" isAdmin ||isCommercial || isRespCommercial">
    <img src="../../assets/bar-icons/affaires.svg">
  </a>

  <a class="nav-item nav-link case-button"  style="font-size:0.8rem;" routerLinkActive="active" routerLink="/technicien-list-chantier" *ngIf="isTechnicien||isResponsableTech">
    <img src="../../assets/bar-icons/travaux.svg">
  </a>

  <a class="nav-item nav-link"  style="font-size:0.8rem;" routerLinkActive="active" routerLink="/commercial-customer-list" *ngIf="isCommercial || isRespCommercial">
    <img *ngIf="router.url != '/commercial-customer-list' " src="../../assets/bar-icons/client.svg">
    <img *ngIf="router.url == '/commercial-customer-list' " src="../../assets/bar-icons/client-active-mobile.svg">
  </a>

  <a class="nav-item nav-link"  style="font-size:0.8rem;" routerLinkActive="active" routerLink="/commercial-customer-list" *ngIf="this.user.role === 'Admin'">
    <img *ngIf="router.url != '/commercial-customer-list' " src="../../assets/bar-icons/client.svg">
    <img *ngIf="router.url == '/commercial-customer-list' " src="../../assets/bar-icons/client-active-mobile.svg">
  </a>

  <a class="nav-item nav-link "  style="font-size:0.8rem;"  routerLink="/messagerie" routerLinkActive="active" *ngIf=" isTechnicien||isResponsableTech ">
    <img src="../../assets/bar-icons/message-icon.svg">
  </a>

  <a *ngIf="showPicture" class="nav-item nav-link profile" style="font-size:0.8rem;z-index: 99999999999999;" routerLinkActive="active" [routerLink]="['/profile',this.sRoles.user.id]">
    <img class="img-profile" src="https://api-crm.s3.eu-west-3.amazonaws.com/{{user.id}}/picture/{{currentPicture}}">
  </a>
  <a *ngIf="!showPicture" class="nav-item nav-link profile" style="font-size:0.8rem;z-index: 99999999999999;"  [routerLink]="['/profile',this.sRoles.user.id]">
    <img class="img-profile" src="../../assets/empty_pp_circle.svg">
  </a>
  </div>

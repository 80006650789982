import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { User } from '@app/_models';
import { apiUrl} from '@environments/env';

@Injectable({ providedIn: 'root' })
export class UserService {
    user(user: any) {
      throw new Error('Method not implemented.');
    }
    apiUrl = apiUrl.apiUrl;

    constructor(public http: HttpClient) {
    }

    getAll() {
      return this.http.get<User[]>(this.apiUrl + '/user/users');
  }
    getById(id: number) {
        return this.http.get<User>(this.apiUrl + '/users/${id}');
    }
}

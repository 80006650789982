<app-loading *ngIf='!customers'></app-loading>
<div class="basic-container mt-5" *ngIf="userIsActive">

	<div class="example-container mat-elevation-z0">
		<div class="example-header">
<!--       <div>
        <mat-form-field class="recherche">
          <input matInput [(ngModel)]="userQuestion"
          type="text" name="userQuestion" id="userQuestions"
          (ngModelChange)="this.userQuestionUpdate.next($event)" placeholder="Recherche">
        </mat-form-field>

      </div>

      <div> -->
        <div>
          <mat-form-field class="recherche">
            <input matInput type="text" placeholder="Nom" [formControl]="searchParams.name">
          </mat-form-field>
          <mat-form-field class="recherche">
          <input matInput type="text" placeholder="Mobile" [formControl]="searchParams.mobile">
          </mat-form-field>
          <mat-form-field class="recherche">
          <input matInput type="text" placeholder="Code Postal" [formControl]="searchParams.cp">
          </mat-form-field>
          <button *ngIf="supprCustomer.length>0" class="btn-trash ml-4" (click)="supprCustommerArray()">
            <img src="../../../assets/icons/trash.svg">
          </button>
        </div>

        <a class="p-3" (click)="showEdit()">Modifier la liste client</a>

        <button class="btn-secondarycrm ml-3 mb-4" (click)="openDialog()">+ Nouveau client</button>
      </div>
  

		<mat-table #table [dataSource]="dataSource" class="col-12" [@listAnimation]="customers?.length">

			<ng-container matColumnDef="name">
				<mat-cell *matCellDef="let element">

          <input *ngIf="showCheck" type="checkbox" [(value)]='element._id' (change)="$event.target.checked? (isfrmChecked = true) : isfrmChecked = false;
          selectCustomers($event,isfrmChecked);">

          {{element.name}} </mat-cell>
			</ng-container>

			<ng-container matColumnDef="city">
				<mat-cell *matCellDef="let element">{{element.cp}} {{element.city}} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="mobile">
				<mat-cell *matCellDef="let element"  class="col-2">
          <div *ngIf="element.mobile != null" > 0{{element.mobile}}</div>
          <div *ngIf="element.mobile == null" > 0{{element.fixe}}</div>

          </mat-cell>
      </ng-container>

      <ng-container matColumnDef="mail">
				<mat-cell *matCellDef="let element" class="col-1">
          <a *ngIf="element.mail" href="mailto:{{element.mail}}"><mat-icon>email</mat-icon></a>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="id">
				<mat-cell *matCellDef="let element" class="justify-content-center">
          <a class="btn-tertiarycrm" [routerLink]="['/commercial-customer-case', element._id]">Accès Affaire <img src="../../../assets/icons/arrow-right-white.svg"></a>
        </mat-cell>
      </ng-container>

			<mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
		</mat-table>
	</div>

</div>




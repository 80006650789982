<h5 *ngIf="this.currentUser.role == 'Superviseur TMK'"  class="mt-5"><strong>Pégase</strong> vous souhaite la bienvenue, <i style="color: var(--tertiarycrm);" >{{currentUser.username}}</i></h5>

<button *ngIf="this.currentUser.role == 'Superviseur TMK'" class="btn-secondarycrm mt-4" style="position: absolute;left: 79%;z-index: 999999;" [routerLink]="['/tmk-cases']"> + Créer un nouveaux RDV</button>

<app-loading *ngIf="loading"></app-loading>
<div class="basic-container mt-5">
  <mat-tab-group mat-align-tabs="start" (selectedTabChange)="tabClick($event)">
    <mat-tab [label]=label_aConfirme>
      <div class="content">
        <div class="row">
            <div class="col-xs-5 col-lg-4 col-sm-4" *ngFor="let case of aConfirme">
              <div class="card" >
                <img *ngIf="currentUser.role != 'Superviseur TMK'" class="delete" src="../../../assets/icons/delete.svg" (click)="deleteCase(case._id)">

                    <div class="card-content" style="display: flex; flex-direction: column; " >
                      <h3> {{case.customer?.name}} </h3>
                      <p> 0{{case.customer?.mobile}} - 0{{case.customer?.fixe}}  </p>
                      <p>{{ case.r1[0].date | date:'medium' }}</p>

                      <div class="d-flex"><strong>Confirmation : </strong> <div style="margin-left: 2%;" [ngClass]="case.stateTmk == 'En cours de confirmation'?'en-cours': case.stateTmk ">  {{case.stateTmk}} </div> </div>
                      <!-- <div  *ngIf="this.currentUser.role != 'Superviseur TMK'" class="mt-2" > -->
                      <div class="mt-2" >

                        <div  class="d-flex" *ngIf="case.commercial==null" > <strong>Affectation :</strong> <div class="ml-2" style="color: #F0C808;" >à affecter </div></div>
                        <div class="d-flex" *ngIf="case.commercial!=null" ><strong>Affectation :</strong><div class="ml-2" style="color:  #78C091;;" >Affecté </div> </div>
                      </div>

                          <button style="margin: 25px 0 0 0; display: flex; justify-content: space-around;" class="btn-tertiarycrm col-5" [routerLink]="['/super-tmk-case-detail', case._id]">
                          Accès Affaire
                           <img style="width: 8px;position: relative;bottom: -3px;" src="../../../assets/icons/send-message.svg">
                          </button>
                          <!-- <p *ngIf="currentUser.role != 'Superviseur TMK'">{{case.customer?.origin}}</p> -->
                          <p class="origin">{{case.customer?.origin}}</p>

                    </div>
                  </div>
                </div>
            </div>
          </div>
    </mat-tab>

    <mat-tab [label]=label_rdvPris>
      <div class="content">
        <div class="row">
            <div class="col-xs-5 col-lg-4 col-sm-4" *ngFor="let case of rdvPris">
              <div class="card" >
                <img *ngIf="currentUser.role != 'Superviseur TMK'" class="delete" src="../../../assets/icons/delete.svg" (click)="deleteCase(case._id)">

                    <div class="card-content" style="display: flex; flex-direction: column; " >
                      <h3> {{case.customer?.name}} </h3>
                      <p> 0{{case.customer?.mobile}} - 0{{case.customer?.fixe}}  </p>
                      <p>{{ case.r1[0].date | date:'medium' }}</p>

                      <div class="d-flex"><strong>Confirmation : </strong> <div style="margin-left: 2%;" [ngClass]="case.stateTmk == 'En cours de confirmation'?'en-cours': case.stateTmk ">  {{case.stateTmk}} </div> </div>
                      <!-- <div  *ngIf="this.currentUser.role != 'Superviseur TMK'" class="mt-2" > -->
                        <div class="mt-2" >
                        <div  class="d-flex" *ngIf="case.commercial==null" > <strong>Affectation :</strong> <div class="ml-2" style="color: #F0C808;" >à affecter </div></div>
                        <div class="d-flex" *ngIf="case.commercial!=null" ><strong>Affectation :</strong><div class="ml-2" style="color:  #78C091;;" >Affecté </div> </div>
                      </div>

                          <button style="margin: 25px 0 0 0; display: flex; justify-content: space-around;" class="btn-tertiarycrm col-5" [routerLink]="['/super-tmk-case-detail', case._id]">
                          Accès Affaire
                           <img style="width: 8px;position: relative;bottom: -3px;" src="../../../assets/icons/send-message.svg">
                          </button>
                          <!-- <p *ngIf="currentUser.role != 'Superviseur TMK'">{{case.customer?.origin}}</p> -->
                          <p class="origin">{{case.customer?.origin}}</p>

                    </div>
                  </div>
                </div>
            </div>
          </div>
    </mat-tab>

    <mat-tab [label]=label_confirme>
      <div class="content">
        <div class="row">
            <div class="col-xs-5 col-lg-4 col-sm-4" *ngFor="let case of confirme">
              <div class="card" >
                <img *ngIf="currentUser.role != 'Superviseur TMK'" class="delete" src="../../../assets/icons/delete.svg" (click)="deleteCase(case._id)">

                    <div class="card-content" style="display: flex; flex-direction: column; " >
                      <h3> {{case.customer?.name}} </h3>
                      <p> 0{{case.customer?.mobile}} - 0{{case.customer?.fixe}}  </p>
                      <p>{{ case.r1[0].date | date:'medium' }}</p>

                      <div class="d-flex"><strong>Confirmation : </strong> <div style="margin-left: 2%;" [ngClass]="case.stateTmk == 'En cours de confirmation'?'en-cours': case.stateTmk ">  {{case.stateTmk}} </div> </div>
                      <!-- <div  *ngIf="this.currentUser.role != 'Superviseur TMK'" class="mt-2" > -->
                        <div class="mt-2" >
                        <div  class="d-flex" *ngIf="case.commercial==null" > <strong>Affectation :</strong> <div class="ml-2" style="color: #F0C808;" >à affecter </div></div>
                        <div class="d-flex" *ngIf="case.commercial!=null" ><strong>Affectation :</strong><div class="ml-2" style="color:  #78C091;;" >Affecté </div> </div>
                      </div>

                          <button style="margin: 25px 0 0 0; display: flex; justify-content: space-around;" class="btn-tertiarycrm col-5" [routerLink]="['/super-tmk-case-detail', case._id]">
                          Accès Affaire
                           <img style="width: 8px;position: relative;bottom: -3px;" src="../../../assets/icons/send-message.svg">
                          </button>
                          <!-- <p *ngIf="currentUser.role != 'Superviseur TMK'">{{case.customer?.origin}}</p> -->
                          <p class="origin">{{case.customer?.origin}}</p>

                    </div>
                  </div>
                </div>
            </div>
          </div>

    </mat-tab>

    <mat-tab  [label]=label_arepo>
      <div class="content">
        <div class="row">
            <div class="col-xs-5 col-lg-4 col-sm-4" *ngFor="let case of arepo">
              <div class="card" >
                <img *ngIf="currentUser.role != 'Superviseur TMK'" class="delete" src="../../../assets/icons/delete.svg" (click)="deleteCase(case._id)">

                    <div class="card-content" style="display: flex; flex-direction: column; " >
                      <h3> {{case.customer?.name}} </h3>
                      <p> 0{{case.customer?.mobile}} - 0{{case.customer?.fixe}}  </p>
                      <p>{{ case.r1[0].date | date:'medium' }}</p>

                      <div class="d-flex"><strong>Confirmation : </strong> <div style="margin-left: 2%;" [ngClass]="case.stateTmk == 'En cours de confirmation'?'en-cours': case.stateTmk ">  {{case.stateTmk}} </div> </div>
                      <div  *ngIf="this.currentUser.role != 'Superviseur TMK'" class="mt-2" >
                        <!-- <div class="mt-2" > -->
                        <div  class="d-flex" *ngIf="case.commercial==null" > <strong>Affectation :</strong> <div class="ml-2" style="color: #F0C808;" >à affecter </div></div>
                        <div class="d-flex" *ngIf="case.commercial!=null" ><strong>Affectation :</strong><div class="ml-2" style="color:  #78C091;;" >Affecté </div> </div>
                      </div>

                          <button style="margin: 25px 0 0 0; display: flex; justify-content: space-around;" class="btn-tertiarycrm col-5" [routerLink]="['/super-tmk-case-detail', case._id]">
                          Accès Affaire
                           <img style="width: 8px;position: relative;bottom: -3px;" src="../../../assets/icons/send-message.svg">
                          </button>
                          <p *ngIf="currentUser.role != 'Superviseur TMK'">{{case.customer?.origin}}</p>
                          <!-- <p class="origin">{{case.customer?.origin}}</p> -->

                    </div>
                  </div>
                </div>
            </div>
          </div>
    </mat-tab>

    <mat-tab [label]=label_annulation>
      <div class="content">
        <div class="row">
            <div class="col-xs-5 col-lg-4 col-sm-4" *ngFor="let case of annulation">
              <div class="card" >
                <img *ngIf="currentUser.role != 'Superviseur TMK'" class="delete" src="../../../assets/icons/delete.svg" (click)="deleteCase(case._id)">

                    <div class="card-content" style="display: flex; flex-direction: column; " >
                      <h3> {{case.customer?.name}} </h3>
                      <p> 0{{case.customer?.mobile}} - 0{{case.customer?.fixe}}  </p>
                      <p>{{ case.r1[0].date | date:'medium' }}</p>

                      <div class="d-flex"><strong>Confirmation : </strong> <div style="margin-left: 2%;" [ngClass]="case.stateTmk == 'En cours de confirmation'?'en-cours': case.stateTmk ">  {{case.stateTmk}} </div> </div>
                      <div  *ngIf="this.currentUser.role != 'Superviseur TMK'" class="mt-2" >
                        <!-- <div class="mt-2" > -->
                        <div  class="d-flex" *ngIf="case.commercial==null" > <strong>Affectation :</strong> <div class="ml-2" style="color: #F0C808;" >à affecter </div></div>
                        <div class="d-flex" *ngIf="case.commercial!=null" ><strong>Affectation :</strong><div class="ml-2" style="color:  #78C091;;" >Affecté </div> </div>
                      </div>

                          <button style="margin: 25px 0 0 0; display: flex; justify-content: space-around;" class="btn-tertiarycrm col-5" [routerLink]="['/super-tmk-case-detail', case._id]">
                          Accès Affaire
                           <img style="width: 8px;position: relative;bottom: -3px;" src="../../../assets/icons/send-message.svg">
                          </button>
                          <p *ngIf="currentUser.role != 'Superviseur TMK'">{{case.customer?.origin}}</p>
                          <!-- <p class="origin">{{case.customer?.origin}}</p> -->

                    </div>
                  </div>
                </div>
            </div>
          </div>

    </mat-tab>
    <mat-tab [label]=label_signe *ngIf="currentUser.role === 'Comptabilité' || currentUser.mail === 'admin@enerconfort.com' ">
      <div class="content">
        <app-loading *ngIf="signeBeenergie.length === 0"></app-loading>

        <div class="row">
            <div class="col-xs-5 col-lg-4 col-sm-4" *ngFor="let case of signeBeenergie">
              <div class="card" >
                <img *ngIf="currentUser.role != 'Superviseur TMK'" class="delete" src="../../../assets/icons/delete.svg" (click)="deleteCase(case._id)">

                    <div class="card-content" style="display: flex; flex-direction: column; " >
                      <h3> {{case.customer?.name}} </h3>
                      <p> 0{{case.customer?.mobile}} - 0{{case.customer?.fixe}}  </p>
                      <p><u>R1:</u> {{ case.r1[0].date | date:'medium' }}</p>
                      <p *ngIf="case.state != 'Annulée'"><u>Signature:</u> {{ case.datesign | date:'medium' }}</p>
                      <p *ngIf="case.state === 'Annulée'"><u>Annulation:</u> {{ case.datecancel | date:'medium' }}</p>

                      <div  *ngIf="this.currentUser.role != 'Superviseur TMK'" class="mt-2" >
                        <!-- <div class="mt-2" > -->
                        <div class="d-flex justify-content-between"><strong>{{ case.commercial.username }}</strong> {{ case.price_ht | currency:'EUR':"symbol" }} HT</div>
                      </div>

                          <button style="margin: 25px 0 0 0; display: flex; justify-content: space-around;" class="btn-tertiarycrm col-5" [routerLink]="['/case-template', case._id]">
                          Accès Affaire
                           <img style="width: 8px;position: relative;bottom: -3px;" src="../../../assets/icons/send-message.svg">
                          </button>
                          <p class="origin" [ngStyle]="{'color':case.state === 'Signée' ? '#78C091' : '#f45b69' }">{{ case.state }}</p>

                    </div>
                  </div>
                </div>
            </div>
          </div>

    </mat-tab>
  </mat-tab-group>
</div>

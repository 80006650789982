import { CustomersService } from './../../services/customers.service';
import { UsersService } from './../../services/users.service';
import { AuthenticationService } from './../../_services/authentication.service';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CasesService } from '@app/services/cases.service';
import { HistoriqueService } from '@app/services/historique.service';



@Component({
  selector: 'app-super-tmk-case-detail',
  templateUrl: './super-tmk-case-detail.component.html',
  styleUrls: ['./super-tmk-case-detail.component.scss']
})
export class SuperTmkCaseDetailComponent implements OnInit {

  currentUser
  caseId;
  case;
  caseDate;
  buttonQualification = false;
  commerciaux =[];

  constructor(
    private route: ActivatedRoute,
    private authenticationService: AuthenticationService,
    private sCases: CasesService,
    private sUsers: UsersService,
    private sCustomer: CustomersService,
    private sHistoriques: HistoriqueService,

  ) { }

  ngOnInit(): void {
    this.authenticationService.user.subscribe(x => this.currentUser = x);

    this.caseId = this.route.snapshot.params['id'];
    this.showCaseById()

    this.sUsers.getAllCommerciaux().subscribe(res =>{

      this.commerciaux = res['users']
      console.log(this.commerciaux)
    })
  }

  showCaseById() {
    return new Promise(resolve => {

    this.sCases.getCaseById(this.caseId).subscribe(res => {
      this.case = res[0];
      this.caseDate = this.case.r1[0]
      resolve(res);
        }, error => {
          console.log(error);
      });
    });
  }

  saveChange(reason,change,value) {

    this.sCases.editCase(this.case, this.caseId).then(res => {
      if(res['error']) {
        return res.error;
      }
    })

   this.sHistoriques.createHistorique(this.caseId, this.case.customer._id, reason, change,value,this.currentUser.id).then(res => {
     if (res['error']) {
       alert(res);
     }
   });

  }

  saveChangeCommentaire(reason,change,value) {
      this.sCases.editCase(this.case, this.caseId).then(res => {
        if(res['error']) {
          return res.error;
        }
      })
    this.sHistoriques.createHistorique(this.caseId, this.case.customer._id, reason, change,value,this.currentUser.id).then(res => {
      console.log(res);
      if (res['error']) {
        alert(res);
      }
    });
  }

  saveChangeConfirmation(reason,change,value) {
    if(this.case.stateTmk == "Confirmé" ){
      this.case.customer.isActive = true
    }else if(this.case.stateTmk == "En cours de confirmation" ) {
      this.case.customer.isActive = false
      this.case.commercial = null
    }
    else {
      this.case.customer.isActive = false
      this.case.commercial = '5ff2d5e7771393000445304d'
    }

     this.sCases.editCase(this.case, this.caseId).then(res => {
      this.sCustomer.editCustomer(this.case.customer, this.case.customer._id).then(cus => {
      })
      if(res['error']) {
        return res.error;
      }
      this.sHistoriques.createHistorique(this.caseId, this.case.customer._id, reason, change,value,this.currentUser.id).then(res => {
        console.log(res);
        if (res['error']) {
          alert(res);
        }
      });
    })
}

  saveChangeCommercial(reason,change,value) {
    if(confirm("Êtes-vous sûr de vouloir affecter ce commercial ?")) {
      if(this.case.commercial != null ){
      this.case.stateTmk = "Confirmé"
      this.case.customer.isActive = true
      }else{
        this.case.stateTmk = "En cours de confirmation"
      this.case.customer.isActive = false
      }
      this.sCustomer.editCustomer(this.case.customer, this.case.customer._id).then(res =>{
      })
      console.log("test " , this.case)
      this.sCases.editCase(this.case, this.caseId).then(res => {
        if(res['error']) {
          return res.error;
        }else{
          this.showCaseById()
        }
      })
      this.sHistoriques.createHistorique(this.caseId, this.case.customer._id, reason, change,value,this.currentUser.id).then(res => {
        console.log(res);
        if (res['error']) {
          alert(res);
        }
      });
    }

}


  toggleQualification(value){
    this.case.isActive = value
    console.log("Ceci est un case active" + this.case.isActive)
    this.sCases.editCase(this.case, this.caseId).then(res => {
      if(res['error']) {
        return res.error;
      }
    })
  }
}

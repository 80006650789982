import { Component, OnInit, ViewChild } from '@angular/core';
import { AuthenticationService } from './_services';
import { User } from './_models';
import { Router } from '@angular/router';
import { UsersService } from './services/users.service';
import { MatSnackBar } from '@angular/material/snack-bar';

import { io, Socket } from 'socket.io-client';
import { Role } from '@app/_models';
import { apiUrl } from '@environments/env';

import { ChatserviceService } from './services/chatservice.service';
import { ChildToParentService } from './services/child-to-parent.service';

import { HotToastService } from '@ngneat/hot-toast';

@Component({
  selector: 'app',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  @ViewChild('template') ngTemplate;

  user: User;
  mobile;
  router;
  private socket: Socket;
  currentUser = undefined;
  isAdmin = false;
  newMessage;
  isSidenavOpen = false;
  userNotif;
  toastRef;

  constructor(
    private authenticationService: AuthenticationService,
    public _router: Router,
    private sUsers: UsersService,
    private _snackBar: MatSnackBar,
    private chatService: ChatserviceService,
    private toast: HotToastService,
    private ChildToParent: ChildToParentService
  ) {
    this.authenticationService.user.subscribe((x) => {
      if (x) {
        this.currentUser = x;
        this.isAdmin =
          (this.currentUser && this.currentUser.role === Role.Admin) ||
          this.currentUser.role === Role.RespAdv ||
          this.currentUser.role === Role.Adv;
        this.socket = io(apiUrl.apiUrl, {
          transports: ['websocket'],
          query: { token: x.token, userId: x.id.toString() },
        });
      }
    });

    if (window.screen.width <= 900) {
      this.mobile = true;
    }
    this.getUser();

    // CHAT NOTIFICATION

    this.chatService.newMessageReceived().subscribe((data: any) => {
      if (this.currentUser.id !== data.read[0]) {
        this.newMessage = data;

        var toastRef = this.toast.show(this.ngTemplate, {
          data: {
            dataSender: data.sender,
            dataDate: this.transformDate(data.created_at),
            dataText: this.truncate(data.text, 30),
          },
          position: 'bottom-right',
          autoClose: false,
          //duration: 30000,
          dismissible: true,
          style: {
            position: 'relative',
            padding: '10px 16px',
            boxShadow: '7px 7px 22px rgba(33, 38, 75, 0.15)',
            width: '342px',
            borderRadius: '12px',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-start',
            alignItems: 'start',
          },
        });
      }
    });
  }

  truncate(str, n) {
    return str.length > n ? str.substr(0, n - 1) + ' ...' : str;
  }

  transformDate(date: any) {
    let dateMoment = date;
    let dateRemontee = new Date(dateMoment).toLocaleString('fr');

    return dateRemontee;
  }

  navigateTo() {
    // this.toastRef.close()
    this._router.navigate(['messagerie']);

    this.ChildToParent.comeFromNotification.next(true);
    this.ChildToParent.idRoom$.next(this.newMessage.groupId);
  }

  async getUser() {
    this.authenticationService.user.subscribe((x) => {
      this.user = x;
      setTimeout(() => {
        this.sUsers.getUserById(this.user.id).subscribe((res) => {
          if (res[0].isActive === false) {
            this.authenticationService.logout();
            this._snackBar.open("Vous n'êtes pas autorisé", '', {
              duration: 2000,
              panelClass: ['delete-snackbar'],
            });
          } else {
            if (this.mobile) {
              if (!res[0].refreshMobile) {
                // edit refreshMobile a true
                this.sUsers
                  .editUser({ refreshMobile: true }, res[0]._id)
                  .then((res) => {
                    this.authenticationService.logout();
                    this._snackBar.open('Veuillez vous reconnecter', '', {
                      duration: 55000,
                      verticalPosition: 'top',
                      panelClass: ['info2-snackbar'],
                    });
                  });
                //logout
              }
            } else {
              if (!res[0].refreshDesktop) {
                this.sUsers
                  .editUser({ refreshDesktop: true }, res[0]._id)
                  .then((res) => {
                    this.authenticationService.logout();
                    this._snackBar.open('Veuillez vous reconnecter', '', {
                      duration: 5000,
                      verticalPosition: 'top',
                      panelClass: ['info2-snackbar'],
                    });
                  });
              }
            }
          }
        });
      }, 2000);
    });
  }

  // WS SW

  /*   requestPermission() {
    Notification.requestPermission().then(permission => {
      if (permission === 'granted') {
        this.subscribeToPush();
      }
    });
  }

  subscribeToPush() {
    navigator.serviceWorker.ready.then((registration: ServiceWorkerRegistration) => {
      registration.pushManager.subscribe({
        userVisibleOnly: true,
        applicationServerKey: this.urlBase64ToUint8Array('BFYHd582XuVK79U8GyapadIHRV-LFHBkrjTSgroNbyDzY_2qT4v3222BR0hF7fEtQxU3-5cTmf0jVKyxgRMX4_4')
      }).then(subscription => {
        if (this.user && this.user.id) {
          this.saveSubscription(subscription, this.currentUser.id);
        } else {
          console.error('Utilisateur non disponible pour la souscription');
        }
      }).catch(err => {
        console.log('Failed to subscribe the user: ', err);
      });
    });
  }

  saveSubscription(subscription: PushSubscription, userId: string) {
    //fetch('http://localhost:4000/api/subscription/subscribe', {
    fetch('https://pegase-api.herokuapp.com/api/subscription/subscribe', {
      method: 'POST',
      body: JSON.stringify({
        subscription,
        userId // Passe l'userId avec la souscription
      }), headers: {
        'Content-Type': 'application/json'
      }
    });
  }

  urlBase64ToUint8Array(base64String: string) {
    const padding = '='.repeat((4 - base64String.length % 4) % 4);
    const base64 = (base64String + padding).replace(/-/g, '+').replace(/_/g, '/');
    const rawData = window.atob(base64);
    return new Uint8Array([...rawData].map(char => char.charCodeAt(0)));
  } */
}

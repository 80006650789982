import { Injectable } from '@angular/core';
import { AuthenticationService } from '@app/_services';

@Injectable({
  providedIn: 'root'
})
export class RolesService {

  user;
  userRole;
  constructor(
    private authenticationService: AuthenticationService,

  ) {
    this.authenticationService.user.subscribe(x => this.user = x);
    console.log(this.user);
    if(this.user) {
      this.userRole = this.user.role;
    }
   }

}

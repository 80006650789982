<div style="margin-right: -16%;" *ngIf="case" >

  <div class="mt-4" [routerLink]="['/super-tmk-dashboard']"> <img src="../../../assets/icons/retour.svg">  Retour </div>

<div class="info-customer mt-5">
  <h3 class="customer-name"> {{case.customer.name}}</h3>
  <h4>0{{case.customer.mobile}}  0{{case.customer.fixe}}</h4>
  <div> {{case.customer.address}} {{case.customer.cp}}  {{case.customer.city}} </div>
  <div>{{case.title}}</div>
 </div>

 <div class="mt-3 d-flex">
  <div class="d-flex">
    <strong>RDV pris le: </strong>  <div class="ml-2" style="color: var(--tertiarycrm);" >{{case.datecase  | date:'medium' }} </div>
  </div>
  <div style="display: flex;  margin-left: 74px; " *ngIf="currentUser.role === 'Superviseur TMK'">
    <strong>RDV pour le: </strong> <div class="ml-2" style="color: var(--tertiarycrm);" > {{case.r1[0].date  | date:'medium'  }}</div>
  </div>
  <div style="display: flex;  margin-left: 74px; " *ngIf="currentUser.role === 'Adv' || currentUser.role === 'Admin'">
  <!-- <div style="display: flex;  margin-left: 74px;"> -->

    <input class="custom-date" placeholder="JJ/MM/AAAA xxhxx" [(ngModel)]="case.r1[0].date" [owlDateTime]="dt1" [owlDateTimeTrigger]="dt1" (ngModelChange)="saveChange('edité','date r1',case.r1[0].date)">
    <owl-date-time #dt1 firstDayOfWeek="1"></owl-date-time>
  </div>
 </div>

 <div>
  <div class="mt-3 d-flex" *ngIf="currentUser.role == 'Superviseur TMK' " >
    <div class="affectation" [ngClass]="case.stateTmk == 'En cours de confirmation'?'en-cours': case.stateTmk " >
    {{case.stateTmk}}
    </div>
    <div style="background-color: #F0C808;" class="affectation" *ngIf="case.commercial === null" >
      En cours d'affectation
    </div>
    <div style="background-color: #78C091;" class="affectation"  *ngIf="case.commercial != null" >
      Affecté
    </div>
  </div>

  <div class="mt-3 d-flex" *ngIf="currentUser.role != 'Superviseur TMK' ">
  <!-- <div class="mt-3 d-flex"> -->

    <mat-select class="col-2 mb-4 choice-com custom-select-state rdv" [ngClass]="case.stateTmk == 'En cours de confirmation'?'en-cours': case.stateTmk"  [(ngModel)]='case.stateTmk' (selectionChange)="saveChangeConfirmation('edité','la confirmation du rdv ',case.stateTmk)"  >
      <mat-option value="A repositionner">A repositionner</mat-option>
      <mat-option value="Confirmé">Confirmé</mat-option>
      <mat-option value="En cours de confirmation">En cours de confirmation</mat-option>
      <mat-option value="Non-conforme">Non-conforme</mat-option>

    </mat-select>

    <div *ngIf="case.commercial === null; then thenCommercial else elseCommercial"></div>

  </div>

    <ng-template #thenCommercial>
      <mat-select [(ngModel)]="case.commercial" class="col-2 mb-4 choice-com custom-select-state rdv Non-conforme" value="case.commercial.username" (selectionChange)="saveChangeCommercial('edité','le commercial ',case.commercial.username)" >
        <mat-option [value]='null'></mat-option>
        <mat-option *ngFor="let commercial of commerciaux" [value]="commercial._id">
          {{commercial.username}}
        </mat-option>
      </mat-select>
    </ng-template>

    <ng-template #elseCommercial  >
      <div class="col-2 mb-4 choice-com custom-select-state rdv Confirmé"  *ngIf="case.commercial != null && case.commercial._id != '5ff2d5e7771393000445304d'" >
        {{case.commercial.username}}
      </div>
    </ng-template>

 </div>
 <div class="d-flex">
   <div class="col-6"></div>
   <div class="col-6">
     <h4>Commentaire du call 2</h4>
   </div>

 </div>
 <div class="d-flex" >
  <div class="col-6"  style="height:30vh; " >
  <textarea class="custom-textarea" placeholder="Description" [(ngModel)]="case.description" (change)="saveChange('edité','la description tmk',case.description)"></textarea>
</div>
<div style="height:30vh" class="col-6">
   <!-- <textarea class="custom-textarea" placeholder="Commentaire" [(ngModel)]="case.description_call2" (change)="saveChangeCommentaire('edité','le commentaire call 2',case.description_call2)"></textarea> -->
   <textarea class="custom-textarea" placeholder="Commentaire" [readonly]="currentUser.role === 'Superviseur TMK'" [(ngModel)]="case.description_call2" (change)="saveChangeCommentaire('edité','le commentaire call 2',case.description_call2)"></textarea>

  </div>


 </div>


</div>

import { CasesService } from '@app/services/cases.service';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { InfosService } from '@app/services/infos.service';
import { RolesService } from '@app/services/roles.service';
import * as moment from 'moment';

@Component({
  selector: 'app-adv-dashboard',
  templateUrl: './adv-dashboard.component.html',
  styleUrls: ['./adv-dashboard.component.scss']
})
export class AdvDashboardComponent implements OnInit {

  user;
  date;
  notifications;
  infosRead = [];
  infosUnread = [];
  countTmkCase=0;
  realtimeDate

  loading = true

  constructor(
    public sRoles: RolesService,
    private sInfos: InfosService,
    private router: Router,
    private sCases: CasesService

  ) {
    this.user = this.sRoles.user;
    this.date = Date.now();
   }

  ngOnInit(): void {
    this.showNotifications();
    this.getNumberTmkCase()
    this.getTime();
    setInterval(() => {
        this.getTime();
        }, 1100);
  }

  getTime() {
    this.realtimeDate = moment().lang("fr").format('HH:mm');
  }

  showNotifications() {
    return new Promise(resolve => {
      this.sInfos.getInfoByAdvId( this.sRoles.user.id).subscribe(res => {
        this.notifications = res;
        this.loading = false
        this.notifications.forEach(element => {
           if(element.vu) {
            this.infosRead.push(element)
          }
          else {
            this.infosUnread.push(element)
          }
        });
        resolve(res);
      }, error => {
        console.log(error);
      });

      })
  }

  goToCase(id, info, infoId) {
    this.saveChange(info, infoId);
    this.router.navigate(['case-template', id]).then(() => {
      window.location.reload();
    });
  }

  saveChange(info, infoId) {
    info.vu = !info.vu;
    this.sInfos.editInfo(info, infoId).then(res => {
      if(res['error']) {
        return res.error;
      }
        this.infosUnread = [];
        this.infosRead = [];
        this.showNotifications();
    });
  }

  deleteInfo(id) {
    const deleteInfo = this.infosUnread.filter(item => item._id !== id);
     this.sInfos.delete(id).subscribe(response => {
      this.infosUnread = [];
      this.infosRead = [];
      this.infosUnread = deleteInfo;
    });
  }
  getNumberTmkCase(){
    this.sCases.getAllTmkCases().subscribe(res =>{
      let allCases = res['cases'];
      let aConfirme = []
      allCases.forEach(element => {
        if(new Date().getDay() === 1) {
         if(new Date(element.r1[0].date) > new Date() && new Date(element.r1[0].date).getDay() === 2) {
           aConfirme.push(element)
         }
        }
        else if(new Date().getDay() === 2) {
         if(new Date(element.r1[0].date) > new Date() && new Date(element.r1[0].date).getDay() === 3) {
           aConfirme.push(element)
         }
        }
        else if(new Date().getDay() === 3) {
         if(new Date(element.r1[0].date) > new Date() && new Date(element.r1[0].date).getDay() === 4) {
           aConfirme.push(element)
         }
        }
        else if(new Date().getDay() === 4) {
         if(new Date(element.r1[0].date) > new Date() && new Date(element.r1[0].date).getDay() === 5) {
           aConfirme.push(element)
         }
        }
        // VENDREDI
        else if(new Date().getDay() === 5) {
          if(new Date(element.r1[0].date) > new Date() && new Date(element.r1[0].date).getDay() === 6 || new Date(element.r1[0].date) > new Date() && new Date(element.r1[0].date).getDay() === 1) {
            aConfirme.push(element)
          }
        }
        aConfirme = aConfirme.filter(c => c.commercial === null)
       this.countTmkCase = aConfirme.length
     });

    })
  }

}

import { Component, Inject,ViewChild } from '@angular/core';
import { AfterViewInit } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CasesService } from '@app/services/cases.service';
import html2canvas from 'html2canvas';
import { SignaturePad } from 'angular2-signaturepad';
import { SignModalTechComponent } from '@app/sign-modal-tech/sign-modal-tech.component';
import { ChantierService } from '@app/services/chantier.service';
import { HistoriqueService } from '@app/services/historique.service';
import { RolesService } from '@app/services/roles.service';

@Component({
  selector: 'app-sign-modal',
  templateUrl: './sign-modal.component.html',
  styleUrls: ['./sign-modal.component.scss']
})
export class SignModalComponent implements AfterViewInit {

  isEmpty = true;

  capturedImage
  loading = false
  newChef
  isValid = false
  @ViewChild(SignaturePad) signaturePad: SignaturePad;
  pieceForNotif:Array<any>
  recetteTitle

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    private sCases: CasesService,
    private dialogRef: MatDialog,
    private sChantiers: ChantierService,
    private sHistoriques: HistoriqueService,
    private sRoles: RolesService
    ) {
      this.data = data
      this.newChef = this.data.chantierId.chef
    }

  ngAfterViewInit() {
    this.signaturePad.set('minWidth', 1);
  }

  async signature() {
    await html2canvas(document.querySelector("#capture")).then(canvas => {
      this.capturedImage = canvas.toDataURL("image/jpeg");
      this.openDialog()
    });

  }

  getCurrentKitAndSaveChange(event,key,currentKit) {

    currentKit[key] = event;
    const array = JSON.parse(this.data.case.kits_vendus);
    const index = array.findIndex(item => item.name === currentKit.type);

    array[index] = currentKit
    this.data.case.kits_vendus = JSON.stringify(array)

    this.sCases.editCase(this.data.case, this.data.case._id).then(res => {
    })
  }

  openDialog(): void {
    const dialogRef = this.dialogRef.open(SignModalTechComponent, {
      disableClose: true,
      panelClass: 'sign-modal',
      data: {
        titlePiece: this.data.titlePiece,
        caseId: this.data.caseId,
        kit: this.data.kit,
        case: this.data.case,
        chantierId: this.data.chantierId,
        sign: this.capturedImage,
        form:this.data.form
      }
    });
    dialogRef.afterClosed().subscribe(result => {

    });
  }

  saveChangeChef(chantier,chantierId,reason, change) {
    const value = this.data.chantierId.chef
    this.data.chantierId.chef = this.newChef

     this.sChantiers.editChantier(this.data.chantierId, chantierId).then(res => {
      if(res['error']) {
        return res.error;
      }
    });
    this.sHistoriques.createHistorique(this.data.case._id, this.data.case.customer._id, reason, change,value,this.sRoles.user.id).then(res => {
      if (res['error']) {
        alert(res);
      }
    });
  }
}


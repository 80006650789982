<p
(click)="openFullscreen()"
*ngIf="!fullscreen"
style="text-decoration:underline; margin-top:2%;">passer en plein écran
  <mat-icon style="
  position: relative;
  top: 7px;
  left: 5px;"
>fullscreen</mat-icon>
</p>

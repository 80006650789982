import { NgModule, LOCALE_ID, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { registerLocaleData } from '@angular/common';
import localeFr from '@angular/common/locales/fr';
registerLocaleData(localeFr, 'fr');
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { MatMomentDateModule, MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';

import { JwtInterceptor, ErrorInterceptor } from './_helpers';
import { HomeComponent } from './home';
import { LoginComponent } from './login';
import { AdminDashboardComponent } from './admin/admin-dashboard/admin-dashboard.component';
import { UserDashboardComponent } from './user/user-dashboard/user-dashboard.component';
import { CommercialDashboardComponent } from './commercial/commercial-dashboard/commercial-dashboard.component';
import { CommercialCustomerListComponent } from './commercial/commercial-customer-list/commercial-customer-list.component';
import { AdminUserListComponent } from './admin/admin-user-list/admin-user-list.component';
import { AdminCustomerListComponent } from './admin/admin-customer-list/admin-customer-list.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { CommercialCustomerCaseComponent } from './commercial/commercial-customer-case/commercial-customer-case.component';
import { CommercialCaseDetailComponent } from './commercial/commercial-case-detail/commercial-case-detail.component';
import { CaseCreateComponent } from './case/case-create/case-create.component';
import { DemoMaterialModule } from './material-module';
import { AddPieceComponent } from './pieces/add/add-piece.component';
import { ListPieceComponent } from './pieces/list/list-piece.component';
import { AdminCustomerCaseDetailComponent } from './admin/admin-customer-case-detail/admin-customer-case-detail.component';
import { TmkCasesComponent } from './tmk/tmk-cases/tmk-cases.component';
import { SuperTmkDashboardComponent } from './super-tmk/super-tmk-dashboard/super-tmk-dashboard.component';
import { RdvAddComponent } from './rdv/rdv-add/rdv-add.component';
import { EditPieceComponent } from './pieces/edit-piece/edit-piece.component';
import { AdvDashboardComponent } from './adv/adv-dashboard/adv-dashboard.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { CaseTemplateComponent } from './case/case-template/case-template.component';
import { SuperTmkCaseDetailComponent } from './super-tmk/super-tmk-case-detail/super-tmk-case-detail.component'
import { DetailPieceComponent } from './pieces/detail-piece/detail-piece.component';
import { CaseFinancementComptantComponent } from './case/case-financement-comptant/case-financement-comptant.component';
import { CaseFinancementListComponent } from './case/case-financement/case-financement-list/case-financement-list.component';
import { CaseFinancementAddComponent } from './case/case-financement/case-financement-add/case-financement-add.component';
import { CaseFinancementEditComponent } from './case/case-financement/case-financement-edit/case-financement-edit.component';
import { TechnicienDashboardComponent } from './technicien/technicien-dashboard/technicien-dashboard.component';
import { SuperTmkCaseListComponent } from './super-tmk/super-tmk-case-list/super-tmk-case-list.component';
import { AdminCaseAffectationComponent } from './admin/admin-case-affectation/admin-case-affectation.component';
import { OwlDateTimeIntl, OwlDateTimeModule, OwlNativeDateTimeModule } from 'ng-pick-datetime';
import { LoadingComponent } from './loading/loading.component';
import { CommentbarComponent } from './commentbar/commentbar.component';
import { StatePipe } from './state.pipe';
import { LabelPipe } from './label.pipe';
import { ListMairieComponent } from './mairies/list-mairie/list-mairie.component';
import { AddMairieComponent } from './mairies/add-mairie/add-mairie.component';
import { EditMairieComponent } from './mairies/edit-mairie/edit-mairie.component';
import { CommercialTemplateComponent } from './commercial/commercial-template/commercial-template.component';
import { FilterPipe } from './filter.pipe';
import { TechnicienCaseDetailComponent } from './technicien/technicien-case-detail/technicien-case-detail.component';
import { ChantierListComponent } from './chantier/chantier-list/chantier-list.component';
import { ChantierDetailComponent } from './chantier/chantier-detail/chantier-detail.component';
import { AdminUserAddComponent } from './admin/admin-user-add/admin-user-add.component';
import { CreateTaskComponent } from './tasks/create-task/create-task.component';
import { BottombarComponent } from './bottombar/bottombar.component';
import { CommercialCaseListComponent } from './commercial/commercial-case-list/commercial-case-list.component';
import { TaskListComponent } from './tasks/task-list/task-list.component';
import { ChantierAddComponent } from './chantier/chantier-add/chantier-add.component';
import { ProfileComponent } from './profile/profile.component';
import { SortPipe } from './pipes/sort.pipe';
import { InfosComponent } from './infos/infos.component';
import { ChartsModule } from 'ng2-charts';
import { OnCreateDirective } from './directives/on-create.directive';
import { AdminQualificationComponent } from './admin/admin-qualification/admin-qualification.component';
import { CaseHistoriqueComponent } from './case/case-historique/case-historique.component';
import { AdminUserStatComponent } from './admin/admin-user-stat/admin-user-stat.component';
import { RoundProgressModule } from 'angular-svg-round-progressbar';
import { AdminQualificationDetailComponent } from './admin/admin-qualification-detail/admin-qualification-detail.component';
import { AdminSuiviAnnuelComponent } from './admin/admin-suivi-annuel/admin-suivi-annuel.component';
import { TaskCaseListComponent } from './tasks/task-case-list/task-case-list.component';
import { DeleteBarComponent } from './snackbars/delete-bar/delete-bar.component';
import { AddBarComponent } from './snackbars/add-bar/add-bar.component';
import { EditBarComponent } from './snackbars/edit-bar/edit-bar.component'
import { TechnicienListChantierComponent } from './technicien/technicien-list-chantier/technicien-list-chantier.component';

// Groups
import { GroupsListComponent } from './groups/groups-list/groups-list.component';
import { GroupsDetailComponent } from './groups/groups-detail/groups-detail.component';
import { GroupsCreateComponent } from './groups/groups-create/groups-create.component';
import { GroupsMessageComponent } from './groups/message/message.component';
import { ChantierChoiceComponent } from './chantier/chantier-choice/chantier-choice.component';
import { ComptaDashboardComponent } from './compta/compta-dashboard/compta-dashboard.component';
import { ComptaCaseDetailComponent } from './compta/compta-case-detail/compta-case-detail.component';
import { AgGridModule } from 'ag-grid-angular';
import { CaseTableComponent } from './case/case-table/case-table.component';
import { CaseCmComponent } from './case/case-cm/case-cm.component';
import { CaseCmTemplateComponent } from './case/case-cm-template/case-cm-template.component';
import { ComptaCmTableComponent } from './compta/compta-cm-table/compta-cm-table.component';
import { ComptaCmDetailComponent } from './compta/compta-cm-detail/compta-cm-detail.component'
import { SpectateurDashboardComponent } from './spectateur/spectateur-dashboard/spectateur-dashboard.component';
import { CustomerInfoComponent } from './spectateur/customer-info/customer-info.component';
import { ComptaTemplateComponent } from './compta/compta-template/compta-template.component'
import { AgencyPipe } from './agency.pipe'
import { MatDatepickerModule } from '@angular/material/datepicker';
import { ManquantePieceComponent } from './pieces/manquante-piece/manquante-piece.component';
import { FavoriteComponent } from './favorite/favorite.component';
import { LogistiqueDashboardComponent } from './logistique/logistique-dashboard/logistique-dashboard.component';
import { LogistiqueDetailComponent } from './logistique/logistique-detail/logistique-detail.component';
import { AdminFinancementComponent } from './admin/admin-financement/admin-financement.component';
import { TitlecasePipe } from './titlecase.pipe';
import { CaseProductsComponent } from './case/case-products/case-products.component';
import { ComptaPrimesDetailComponent } from './compta/compta-primes-detail/compta-primes-detail.component';
import { ComptaPrimesTableComponent } from './compta/compta-primes-table/compta-primes-table.component';
import { CasePrimeAddComponent } from './case-prime/case-prime-add/case-prime-add.component';
import { CasePrimeEditComponent } from './case-prime/case-prime-edit/case-prime-edit.component';
import { CasePrimeListComponent } from './case-prime/case-prime-list/case-prime-list.component';
import { ProductCardComponent } from './products/product-card/product-card.component';
import { ProductListComponent } from './products/product-list/product-list.component';
import { ConfirmDialogComponent } from './dialogs/confirm-dialog/confirm-dialog.component';
import { SuiviAffairesComponent } from './commercial/suivi-affaires/suivi-affaires.component'
import { BookComponent } from './book/book.component';
import { Part0Component } from './book/part0/part0.component';
import { Part1Component } from './book/part1/part1.component';
import { Part2Component } from './book/part2/part2.component';
import { Part3Component } from './book/part3/part3.component';
import { Part4Component } from './book/part4/part4.component';
import { Part5Component } from './book/part5/part5.component';
import { Part6Component } from './book/part6/part6.component';
import { Part7Component } from './book/part7/part7.component';
import { Part8Component } from './book/part8/part8.component'

//Slider
import { NgxSliderModule } from '@angular-slider/ngx-slider';

import { Part9Component } from './book/part9/part9.component';
import { Part9BisComponent } from './book/part9bis/part9bis.component';

import { Part10Component } from './book/part10/part10.component';
import { Part11Component } from './book/part11/part11.component';
import { Part12Component } from './book/part12/part12.component';
import { Part13Component } from './book/part13/part13.component';
import { Part14Component } from './book/part14/part14.component';

import { Part15Component } from './book/part15/part15.component';
import { Part16Component } from './book/part16/part16.component';
import { Part17Component } from './book/part17/part17.component';

import { Part18Component } from './book/part18/part18.component';

import { Part19Component } from './book/part19/part19.component';

import { LogistiqueStockComponent } from './logistique/logistique-stock/logistique-stock.component';
import { LogistiqueVisualizeComponent } from './logistique/logistique-visualize/logistique-visualize.component';
import { SignModalComponent } from './sign-modal/sign-modal.component';
import { HeaderInfoComponent } from './pv/header-info/header-info.component';

import { ProductsHeadComponent } from './technicien/products-head/products-head.component';
import { ChoicesComponent } from './pv/choices/choices.component';
import { LivraisonVisualizeComponent } from './pv/livraison-visualize/livraison-visualize.component';
import { ProgressbarComponent } from './progressbar/progressbar.component';
import { RecetteTemplateComponent } from './pv/recette-template/recette-template.component';
import { RecetteVmcComponent } from './pv/recettes/recette-vmc/recette-vmc.component';
import { RecetteAdoucisseurComponent } from './pv/recettes/recette-adoucisseur/recette-adoucisseur.component';
import { RecetteManteComponent } from './pv/recettes/recette-mante/recette-mante.component';
import { NoModalComponent } from './pv/no-modal/no-modal.component';
import { RecetteBtMonoblocComponent } from './pv/recettes/recette-bt-monobloc/recette-bt-monobloc.component';
import { RecetteBtSplitComponent } from './pv/recettes/recette-bt-split/recette-bt-split.component';
import { RecettePacaaSplitComponent } from './pv/recettes/recette-pacaa-split/recette-pacaa-split.component';
import { RecettePacaaGainableComponent } from './pv/recettes/recette-pacaa-gainable/recette-pacaa-gainable.component';
import { RecettePacaeComponent } from './pv/recettes/recette-pacae/recette-pacae.component';
import { RecettePvMicroOnduleurComponent } from './pv/recettes/recette-pv-micro-onduleur/recette-pv-micro-onduleur.component';
import { RecettePvOnduleurComponent } from './pv/recettes/recette-pv-onduleur/recette-pv-onduleur.component';
import { ModalPrevisualizeComponent } from './pv/modal-previsualize/modal-previsualize.component'
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { ReceptionVisualizeComponent } from './pv/reception-visualize/reception-visualize.component';
import { SignaturePadModule } from 'angular2-signaturepad';
import { CreateTaskGddComponent } from './tasks/create-task-gdd/create-task-gdd.component';
import { CardGddComponent } from './tasks/card-gdd/card-gdd.component';
import { SearchFilterPipe } from './search-filter.pipe';
import { TableGddComponent } from './tasks/table-gdd/table-gdd.component';
import { SignModalTechComponent } from './sign-modal-tech/sign-modal-tech.component';
import { SuiviDossiersComponent } from './adv/suivi-dossiers/suivi-dossiers.component';
import { SuiviDossiersDetailComponent } from './adv/suivi-dossiers-detail/suivi-dossiers-detail.component';
import { ComptaLeadsComponent } from './compta/compta-leads/compta-leads.component'
import { HotToastModule } from '@ngneat/hot-toast';

import { AddLeadComponent } from './communication/add-lead/add-lead.component';
import { BiVisualizeComponent } from './pv/bi-visualize/bi-visualize.component';
import { FullscreenComponent } from './book/fullscreen/fullscreen.component';
import { CustomerModalComponent } from './dialogs/customer-modal/customer-modal.component';
import { AdminDashboardModalComponent } from './admin/admin-dashboard-modal/admin-dashboard-modal.component';;
import { SavDashboardComponent } from './sav-dashboard/sav-dashboard.component';
import { AddProductComponent } from './add-product/add-product.component';
import { CardReponseComponent } from './book/card-reponse/card-reponse.component';
import { TestConnaissancesComponent } from './book/test-connaissances/test-connaissances.component';
import { Part4bisComponent } from './book/part4bis/part4bis.component';
import { Part5bisComponent } from './book/part5bis/part5bis.component';
import { Part20Component } from './book/part20/part20.component';
import { AnimatePercentageDirective } from './directives/animate-percentage.directive';
import { Part21Component } from './book/part21/part21.component';


export class DefaultIntl extends OwlDateTimeIntl {

  cancelBtnLabel = 'Retour';
  setBtnLabel = 'Valider';
}

@NgModule({
  imports: [
    BrowserModule,
    ReactiveFormsModule,
    HttpClientModule,
    AppRoutingModule,
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatButtonModule,
    MatCardModule,
    FormsModule,
    DemoMaterialModule,
    BrowserModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    ChartsModule,
    RoundProgressModule,
    MatDatepickerModule,
    MatMomentDateModule,
    PdfViewerModule,
    SignaturePadModule,
    AgGridModule,
    NgxSliderModule,
    HotToastModule.forRoot({ reverseOrder: true }),
  ],
  declarations: [
    AppComponent,
    HomeComponent,
    LoginComponent,
    AdminDashboardComponent,
    UserDashboardComponent,
    CommercialDashboardComponent,
    CommercialCustomerListComponent,
    AdminUserListComponent,
    AdminCustomerListComponent,
    CommercialCustomerCaseComponent,
    CommercialCaseDetailComponent,
    CaseCreateComponent,
    AddPieceComponent,
    ListPieceComponent,
    AdminCustomerCaseDetailComponent,
    TmkCasesComponent,
    SuperTmkDashboardComponent,
    RdvAddComponent,
    EditPieceComponent,
    AdvDashboardComponent,
    SidebarComponent,
    CaseTemplateComponent,
    SuperTmkCaseDetailComponent,
    DetailPieceComponent,
    CaseFinancementComptantComponent,
    CaseFinancementListComponent,
    CaseFinancementAddComponent,
    CaseFinancementEditComponent,
    TechnicienDashboardComponent,
    SuperTmkCaseListComponent,
    AdminCaseAffectationComponent,
    LoadingComponent,
    CommentbarComponent,
    StatePipe,
    LabelPipe,
    ListMairieComponent,
    AddMairieComponent,
    EditMairieComponent,
    CommercialTemplateComponent,
    FilterPipe,
    TechnicienCaseDetailComponent,
    TechnicienListChantierComponent,
    ChantierListComponent,
    ChantierDetailComponent,
    AdminUserAddComponent,
    CreateTaskComponent,
    BottombarComponent,
    CommercialCaseListComponent,
    TaskListComponent,
    ChantierAddComponent,
    ProfileComponent,
    SortPipe,
    InfosComponent,
    OnCreateDirective,
    AdminQualificationComponent,
    CaseHistoriqueComponent,
    AdminUserStatComponent,
    AdminQualificationDetailComponent,
    AdminSuiviAnnuelComponent,
    TaskCaseListComponent,
    DeleteBarComponent,
    AddBarComponent,
    EditBarComponent,
    // Groups
    GroupsListComponent,
    GroupsDetailComponent,
    GroupsCreateComponent,
    GroupsMessageComponent,
    ChantierChoiceComponent,
    ComptaDashboardComponent,
    ComptaCaseDetailComponent,
    CaseCmComponent,
    CaseTableComponent,
    CaseCmTemplateComponent,
    ComptaCmTableComponent,
    ComptaCmDetailComponent,
    SpectateurDashboardComponent,
    CustomerInfoComponent,
    CaseTableComponent,
    ComptaTemplateComponent,
    AgencyPipe,
    ManquantePieceComponent,
    FavoriteComponent,
    LogistiqueDashboardComponent,
    LogistiqueDetailComponent,
    AdminFinancementComponent,
    ComptaPrimesTableComponent,
    ComptaPrimesDetailComponent,
    CaseProductsComponent,
    CasePrimeAddComponent, CasePrimeEditComponent, CasePrimeListComponent,
    TitlecasePipe,
    ProductCardComponent,
    ProductListComponent,
    BookComponent,
    Part0Component,
    Part1Component,
    Part2Component,
    Part3Component,
    Part4Component,
    Part5Component,
    Part6Component,
    Part7Component,
    Part8Component,
    Part9Component,
    Part9BisComponent,
    Part10Component,
    Part11Component,
    Part12Component,
    Part13Component,
    Part14Component,
    Part15Component,
    Part16Component,
    Part17Component,
    Part18Component,
    Part19Component,
    Part20Component,
    Part21Component,
    ConfirmDialogComponent,
    SuiviAffairesComponent,
    LogistiqueStockComponent,
    LogistiqueVisualizeComponent,
    SignModalComponent,
    HeaderInfoComponent,
    ProductsHeadComponent,
    ChoicesComponent,
    LivraisonVisualizeComponent,
    ProgressbarComponent,
    RecetteTemplateComponent,
    RecetteVmcComponent,
    RecetteAdoucisseurComponent,
    RecetteManteComponent,
    NoModalComponent,
    RecetteBtMonoblocComponent,
    RecetteBtSplitComponent,
    RecettePacaaSplitComponent,
    RecettePacaaGainableComponent,
    RecettePacaeComponent,
    RecettePvMicroOnduleurComponent,
    RecettePvOnduleurComponent,
    ModalPrevisualizeComponent,
    ReceptionVisualizeComponent,
    CreateTaskGddComponent,
    SearchFilterPipe,
    TableGddComponent,
    CardGddComponent,
    SignModalTechComponent,
    SuiviDossiersComponent,
    SuiviDossiersDetailComponent,
    ComptaLeadsComponent,
    AddLeadComponent,
    AddLeadComponent,
    BiVisualizeComponent,
    FullscreenComponent,
    AnimatePercentageDirective,
    CustomerModalComponent, AdminDashboardModalComponent, AddProductComponent, CardReponseComponent, TestConnaissancesComponent, Part4bisComponent, Part5bisComponent, Part21Component],



  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: LOCALE_ID, useValue: "fr-fr" },
    { provide: OwlDateTimeIntl, useClass: DefaultIntl },
    { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } },
    { provide: HotToastModule }
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})

export class AppModule { }

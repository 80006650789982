import { MatSnackBar } from '@angular/material/snack-bar';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { CustomersService } from '@app/services/customers.service';
import { CasesService } from '@app/services/cases.service';
import { RolesService } from '@app/services/roles.service';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { UsersService } from '@app/services/users.service';

@Component({
  selector: 'app-tmk-cases',
  templateUrl: './tmk-cases.component.html',
  styleUrls: ['./tmk-cases.component.scss']
})
export class TmkCasesComponent implements OnInit {

  tmkAffaire = new FormGroup({});
  caseActive;
  currentRole;
  tmkRole;
  commercialRole;
  advRole;
  stateTmk;

  origin;
  showOrigin = true;

  customers
  datecase;

  kit = [
    { name: 'Adoucisseur d\'eau', type: 'Adoucisseur d\'eau', active: false, },
    { name: 'Ballon thermodynamique', type: 'Ballon thermodynamique', coude_entree_air: null, coude_sortie_air: null, active: false, options_bt: [] },
    { name: 'Batterie', type: 'Batterie', marque: 'Huawei', active: false, },
    { name: 'Mise aux normes du tableau électrique', type: 'Mise aux normes du tableau électrique', active: false, },
    { name: 'PAC Air/Air', type: 'PAC Air/Air', active: false, options_pac: [] },
    { name: 'PAC Air/Eau', type: 'PAC Air/Eau', active: false, options_pac: [] },
    { name: 'Panneaux solaires', type: 'Panneaux solaires', modules: [], onduleurs: [], nbr_relais: null, active: false, },
    { name: 'Thermostat radio', type: 'Thermostat radio', active: false, },
    { name: 'VMC Double Flux', type: 'VMC Double Flux', obturateurs: [], obturateur: null, active: false, },
    { name: 'Isolation', type: 'Isolation', active: false, },
    { name: 'Batterie', type: 'Batterie', active: false, },
  ]

  blocNote =
    'Propriétaire depuis =>' + '\n' +
    'Métier Mr =>' + '\n' +
    'Age, si à la retraite =>' + '\n' +
    'Métier Mme:Age, si à la retraite =>' + '\n' +
    'Factures =>' + '\n' +
    'Superficie + nombre de personnes =>' + '\n' +
    'Système de chauffage Principal =>' + '\n' +
    'Système de chauffage Complémentaire =>' + '\n' +
    'Système d\'eau chaude =>' + '\n' +
    'Système de ventilation =>' + '\n' +
    'Quel importance le prospect accorde à la qualité de l\'air chez lui =>' + '\n' +
    'Que pense-t-il des nouvelles technologies qui apporte + de confort et qui climatisent/chauffent la maison =>' + '\n' +
    'GPS =>' + '\n' +
    'Indication particulière =>' + '\n' +
    'Présence de =>' + '\n' +
    'Indice =>'

  mobileValid = false
  constructor(
    private sCustomers: CustomersService,
    private sCases: CasesService,
    public sUsers: UsersService,
    private sRoles: RolesService,
    private router: Router,
    private dialogRef: MatDialog,
    private _snackBar: MatSnackBar
  ) { }

  ngOnInit(): void {
    this.currentRole = this.sRoles.userRole
    this.sUsers.showUserRole();
    this.showCustomers()

    if (this.currentRole === 'Tmk' || this.currentRole === 'Superviseur TMK') {
      this.tmkAffaire.value.description = this.blocNote
      this.caseActive = false;
      this.tmkRole = this.sRoles.user.id;
      this.advRole = null;
      this.origin = "Be energie";
      this.datecase = '';
      this.stateTmk = "En cours de confirmation";

    }
    else if (this.currentRole === 'Adv' || this.currentRole === 'Admin' || this.currentRole === 'Responsable ADV') {
      this.caseActive = null;
      this.tmkRole = null;
      this.commercialRole = this.tmkAffaire.value.commercial;
      this.stateTmk = null;
      this.advRole = null;
      this.origin = null
      this.datecase = Date.now();
    }
    else if (this.currentRole === 'Commercial' || this.currentRole === 'Responsable d\'agence') {
      this.showOrigin = true;
      this.caseActive = null;
      this.stateTmk = null;
      this.tmkRole = null;
      this.commercialRole = this.sRoles.user.id;
      this.advRole = null;
      this.origin = 'perso';
      this.datecase = Date.now();
    }

    this.tmkAffaire = new FormGroup({
      financement_comptant: new FormGroup({
        'choice': new FormControl(''),
        'cheques': new FormControl(false),
        'cheque1': new FormGroup({
          'date': new FormControl(''),
          'encaissement': new FormControl(false),
          montant: new FormControl(''),
          'date_validation': new FormControl(''),
        }),
        'cheque2': new FormGroup({
          'date': new FormControl(''),
          'encaissement': new FormControl(false),
          montant: new FormControl(''),
          'date_validation': new FormControl(''),
          'validation': new FormControl(false),
        }),
        'cheque3': new FormGroup({
          'date': new FormControl(''),
          'encaissement': new FormControl(false),
          montant: new FormControl(''),
          'validation': new FormControl(false),
        }),
      }),
      title: new FormControl(''),
      tmk: new FormControl(this.tmkRole),
      created_by: new FormControl(this.sRoles.user.id),
      commercial: new FormControl(this.commercialRole),
      adv: new FormControl(this.advRole),
      datecase: new FormControl(this.datecase),
      description: new FormControl(this.currentRole === 'Tmk' || this.currentRole === 'Superviseur TMK' ? this.blocNote : ''),
      mairie: new FormControl(''),
      kits_vendus: new FormControl(JSON.stringify(this.kit)),
      state: new FormControl('Prospection'),
      letter: new FormGroup({
        status: new FormControl(''),
        date: new FormControl(''),
      }),
      r1: new FormGroup({
        debrief: new FormControl(''),
        date: new FormControl(''),
      }),
      rdv_state: new FormGroup({
        status: new FormControl(''),
        why: new FormControl(''),
      }),
      customer: new FormControl(''),
      stateTmk: new FormControl(this.stateTmk),


      tmkClient: new FormGroup({
        origin: new FormControl(this.origin),
        name: new FormControl(''),
        city: new FormControl(''),
        mobile: new FormControl('', Validators.compose([
          Validators.required,
        ])),
        cp: new FormControl('', Validators.compose([
          Validators.required,
          Validators.pattern('^(([0-8][0-9])|(9[0-5])|(2[ab]))[0-9]{3}$')
        ])),
        fixe: new FormControl(''),
        address: new FormControl(''),
        mail: new FormControl('', Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')),
        isActive: new FormControl(this.caseActive),

      }),
    });
  }

  addCustomer() {
    this.sCustomers.createCustomer(this.tmkAffaire.value.tmkClient).then(res => {
      if (res['error']) {
        alert(res.error);
      }
      else {
        this.addCase(res._id);
      }
    });

  }

  showCustomers() {
    this.sCustomers.getAllCustomers(9999).subscribe(res => {
      this.customers = res['customers'];
    });
  }

  addCase(id) {
    this.tmkAffaire.value.customer = id;
    this.sCases.createCase(this.tmkAffaire.value).then(res => {
      this._snackBar.open('Client ajouté avec succès', '', { duration: 2000, verticalPosition: 'bottom', panelClass: ['add-snackbar'] });
      if (this.currentRole === 'Tmk' || this.currentRole === 'Beenergie') {
        this.router.navigate(['tmk-dashboard']);
      }
      else if (this.currentRole === 'Superviseur TMK') {
        this.router.navigate(['super-tmk-dashboard']);

      }
      else {
        this.dialogRef.closeAll();
      }
    });
  }

  comparePhone() {
    if (this.tmkAffaire.value.tmkClient.mobile.toString().length === 9) {
      if (this.customers.find(u => u.mobile === this.tmkAffaire.value.tmkClient.mobile.toString())) {
        this._snackBar.open('Un client existe déjà avec ce numéro de téléphone, demandez une vérification', '', {
          duration: 10000,
          panelClass: ['delete-snackbar']
        });
      }
      else {

        this._snackBar.open('Numéro valide', '', {
          duration: 1000,
          panelClass: ['add-snackbar']
        });
        this.mobileValid = true
      }
    }

  }
  closeModale() {
    this.dialogRef.closeAll();
  }
}

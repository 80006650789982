<div class="sidebar noPrint" *ngIf="user">
  <a class="nav-item nav-link" (click)="goHome()">
    <img class="accueil mt-2" src="../../assets/bar-icons/accueil.svg">
  </a>
  <a class="nav-item nav-link" routerLinkActive="active" [routerLink]="['/spectateur-dashboard']" *ngIf="isSuperTmk">
    <img *ngIf="router.url != '/spectateur-dashboard'" src="../../assets/bar-icons/client.svg">
    <img *ngIf="router.url == '/spectateur-dashboard'" class="active" src="../../assets/bar-icons/client-active.svg">
  </a>

  <a class="nav-item nav-link" routerLinkActive="active" (click)="goToCommercialCustomerList()" *ngIf="isCommercial || isRespCommercial">
    <img *ngIf="router.url != '/commercial-customer-list'" src="../../assets/bar-icons/client.svg">
    <img *ngIf="router.url == '/commercial-customer-list'" class="active" src="../../assets/bar-icons/client-active.svg">
  </a>
  <a class="nav-item nav-link" routerLinkActive="active" routerLink="/commercial-case-list" *ngIf="isCommercial || isRespCommercial">
    <img *ngIf="router.url != '/commercial-case-list'" src="../../assets/bar-icons/affaires.svg" class="mt-2">
    <img *ngIf="router.url == '/commercial-case-list'" class="active" src="../../assets/bar-icons/case-active.svg">
  </a>

  <a class="nav-item" routerLinkActive="active" (click)="goToCustomerList()" *ngIf="isAdmin || isAdv || isCompta">
    <img *ngIf="router.url != '/admin-customer-list'" src="../../assets/bar-icons/affaires.svg" class="mt-2">
    <img *ngIf="router.url == '/admin-customer-list'" class="active" src="../../assets/bar-icons/case-active.svg">
  </a>

  <a class="nav-item" routerLinkActive="active" (click)="goToBeEnergie()" *ngIf="user.mail === 'communication2@enerconfort.com'">
    <img *ngIf="router.url != '/super-tmk-dashboard'" src="../../assets/bar-icons/beenergie.svg" class="mt-4">
    <img *ngIf="router.url == '/super-tmk-dashboard'" class="active" src="../../assets/bar-icons/beenergie-active.svg">
  </a>

<!-----
  <a class="nav-item" routerLinkActive="active" [routerLink]="['/sav-dashboard']" *ngIf="isAdmin || isAdv || isCommercial || isRespCommercial">
    <img *ngIf="router.url != '/sav-dashboard'" src="../../assets/bar-icons/service-client.svg">
    <img *ngIf="router.url == '/sav-dashboard'" class="active" src="../../assets/bar-icons/service-client-active.svg">
  </a>
----->
    <a class="nav-item nav-link" routerLinkActive="active" style="z-index: 99999999999999;" routerLink="/messagerie">
      <img class="groups" src="../../assets/bar-icons/messagerie-white.svg">
    </a>
    <p *ngIf="isDataAvailable" class="compteur-messagerie-desktop">
      <span *ngIf="countUnread" matBadge="{{ countUnread.count }}" matBadgeSize="small"></span>
    </p>

    <a class="nav-item nav-link tache" routerLinkActive="active" style="z-index: 99999999999999;" routerLink="/task-list">
      <img class="tache" src="../../assets/bar-icons/tache-icon.svg">
    </a>
    <p class="compteur-desktop">
      <span *ngIf="infosUnread" matBadge="{{ infosUnread.length }}" matBadgeSize="small"></span>
    </p>
    <p class="compteur-taches">
      <span *ngIf="tasks" matBadge="{{ tasks.length }}" matBadgeSize="small"></span>
    </p>

    <a  class="nav-item nav-link notif" routerLinkActive="active" (click)="showPopup()">
      <img *ngIf="!showPopupNotif" class="tache" src="../../assets/bar-icons/notification-blanc.svg">
    </a>

    <a class="nav-item nav-link profile"  style="font-size:0.8rem;z-index: 99999999999999;" routerLinkActive="active" (click)="openDialog()">
      <img class="img-profile" *ngIf="showPicture" src="https://api-crm.s3.eu-west-3.amazonaws.com/{{user.id}}/picture/{{currentPicture}}">
    </a>
    <a class="nav-item nav-link profile"  style="font-size:0.8rem;z-index: 99999999999999;" (click)="openDialog()">
      <img class="img-profile" *ngIf="!showPicture" src="../../assets/empty_pp_circle.svg">
    </a>


    <a class="nav-item" routerLinkActive="active" (click)="goToTravauxList()" *ngIf="isRespTech">
      <img *ngIf="router.url != '/technicien-list-chantier'" src="../../assets/bar-icons/travaux.svg">
      <img *ngIf="router.url == '/technicien-list-chantier'" class="active" src="../../assets/bar-icons/travaux-active.svg">
    </a>
</div>


<!-- POP UP NOTIF -->

<div *ngIf="showPopupNotif" class="notif-img" (click)="showPopup()">
  <img src="../../assets/bar-icons/notification-select.svg">
</div>
<div class="popup-notif" *ngIf="showPopupNotif">

<div class="row custom-row">
  <button (click)="showUnreadNotif()"  class="btn-secondarycrm col-4  offset-2" [ngClass]="!showRead ? 'actived' : 'disabled'">Non lues ({{ infosUnread.length }})</button>
  <button (click)="showAll()" class="btn-secondarycrm col-4" [ngClass]="showRead ? 'actived' : 'disabled'">Toutes ({{ infosUnread.length + infosRead.length }})</button>
</div>

<div class="row custom-row content-infos" *ngIf="showUnread || showRead">
  <div class="scroll col-12" id="scroll">
    <div *ngFor="let info of infosUnread">
      <div class="card row custom-row d-flex">
        <div *ngIf="info.reason && info.reason === 'pieces'">
          <h5 class="mt-3">{{ info.created_by.username }}</h5>
          <p> à ajouté la pièce {{ info.comment }} le {{ info.created_at | date:'medium' }} pour le client <a (click)="goToCase(info.caseId._id,info,info._id)" style="text-decoration: underline; color: orangered;" *ngIf="info.customerId != null">{{ info.customerId.name }}</a> </p>
        </div>
        <div *ngIf="info.reason && info.reason === 'commentaire'">
          <h5 class="mt-3">{{ info.created_by.username }}</h5>
           <p>vous a mentionné sur un commentaire le {{ info.created_at | date:'medium' }} sur l'affaire de <a (click)="goToCase(info.caseId._id,info,info._id)" style="text-decoration: underline; color: orangered;" *ngIf="info.customerId != null">{{ info.customerId.name }}</a></p>
        </div>
        <div *ngIf="info.reason && info.reason === 'like'">
          <h5 class="mt-3">{{ info.created_by.username }}</h5>
           <p>{{info.comment}}  votre commentaire le {{ info.created_at | date:'medium' }} sur l'affaire de <a (click)="goToCase(info.caseId._id,info,info._id)" style="text-decoration: underline; color: orangered;" *ngIf="info.customerId != null">{{ info.customerId.name }}</a></p>
        </div>
        <div *ngIf="info.reason && info.reason === 'signée'">
          <h5 class="mt-3">{{ info.created_by.username }}</h5>
          <p> à signé avec le client <a (click)="goToCase(info.caseId._id,info,info._id)" style="text-decoration: underline; color: orangered;" *ngIf="info.customerId != null">{{ info.customerId.name }}</a> </p>
        </div>
        <div *ngIf="info.reason && info.reason === 'qualification'">
          <h5 class="mt-3">{{ info.created_by.username }}</h5>
          <p>à qualifé une pièce non conforme sur l'affaire de <a (click)="goToCase(info.caseId._id,info,info._id)" style="text-decoration: underline; color: orangered;" *ngIf="info.customerId != null">{{ info.customerId.name }}</a>
            le {{ info.created_at | date:'medium' }} </p>
        </div>
        <div *ngIf="info.reason && info.reason === 'rappelsign'">
          <h5 class="mt-3">Rappel</h5>
          <p>{{ info.comment }} <a (click)="goToCase(info.caseId._id,info,info._id)" style="text-decoration: underline; color: orangered;" *ngIf="info.customerId != null">{{ info.customerId.name }}</a> </p>
        </div>
        <div *ngIf="info.reason && info.reason === 'rappelsignDA'">
          <h5 class="mt-3">Rappel</h5>
          <p>{{ info.comment }} <a (click)="goToCase(info.caseId._id,info,info._id)" style="text-decoration: underline; color: orangered;" *ngIf="info.customerId != null">{{ info.customerId.name }}</a> </p>
        </div>
        <div *ngIf="info.reason && info.reason === 'rappelprospection'">
          <h5 class="mt-3">Rappel</h5>
          <p>{{ info.comment }} <a (click)="goToCase(info.caseId._id,info,info._id)" style="text-decoration: underline; color: orangered;" *ngIf="info.customerId != null">{{ info.customerId.name }}</a> </p>
        </div>
        <div *ngIf="info.reason && info.reason === 'annulée'">
          <h5 class="mt-3">Affaire annulée</h5>
          <p>L'affaire de <a (click)="goToCase(info.caseId._id,info,info._id)" style="text-decoration: underline; color: orangered;" *ngIf="info.customerId != null">{{ info.customerId.name }}</a> est passée en {{ info.comment }}  </p>
        </div>
        <div *ngIf="info.reason && info.reason === 'chantier'">
          <h5 class="mt-3">{{ info.created_by.username }}</h5>
          <p>a changé le {{ info.comment }} sur l'affaire <a (click)="goToCase(info.caseId._id,info,info._id)" style="text-decoration: underline; color: orangered;" *ngIf="info.customerId != null">{{ info.customerId.name }}</a></p>
        </div>
        <div *ngIf="info.reason && info.reason === 'valid_tarif'">
          <h5 class="mt-3">{{ info.created_by.username }}</h5>
          <p> {{ info.comment }} sur l'affaire <a (click)="goToCase(info.caseId._id,info,info._id)" style="text-decoration: underline; color: orangered;" *ngIf="info.customerId != null">{{ info.customerId.name }}</a></p>
        </div>
        <div *ngIf="info.reason && info.reason === 'facture'">
          <h5 class="mt-3">{{ info.created_by.username }}</h5>
          <p>Facturation de l'affaire <a (click)="goToCase(info.caseId._id,info,info._id)" style="text-decoration: underline; color: orangered;" *ngIf="info.customerId != null">{{ info.customerId.name }}</a></p>
        </div>
        <div *ngIf="info.reason && info.reason === 'piece_manquante'">
          <h5 class="mt-3">{{ info.created_by.username }}</h5>
          <p> {{ info.comment }} sur l'affaire <a [routerLink]="['/commercial-template/', info.caseId._id]" style="text-decoration: underline; color: orangered;" *ngIf="info.customerId != null">{{ info.customerId.name }}</a></p>
        </div>
        <div *ngIf="info.reason && info.reason === 'task_gdd'">
          <h5 class="mt-3">{{ info.created_by.username }}</h5>
          <p> {{ info.comment }} le {{ info.created_at | date:'medium' }} sur l'affaire <a [routerLink]="['/commercial-template/', info.caseId._id]" style="text-decoration: underline; color: orangered;" *ngIf="info.customerId != null">{{ info.customerId.name }}</a></p>
        </div>

        <div *ngIf="info.reason && info.reason === 'recette'">
          <h5 class="mt-3">{{ info.created_by.username }}</h5>
          <p> {{ info.comment }} le {{ info.created_at | date:'medium' }} sur l'affaire <a [routerLink]="['/technicien-dashboard/']" style="text-decoration: underline; color: orangered;" *ngIf="info.customerId != null">{{ info.customerId.name }}</a></p>
        </div>

        <div *ngIf="info.reason && info.reason === 'rappelmairie'">
          <h5 class="mt-3">Rappel</h5>
          <p> {{ info.comment }} sur l'affaire <a [routerLink]="['/case-template/', info.caseId._id]" style="text-decoration: underline; color: orangered;" *ngIf="info.customerId != null">{{ info.customerId.name }}</a></p>
        </div>
        <div *ngIf="info.reason && info.reason === 'rappelfi'">
          <h5 class="mt-3">Rappel</h5>
          <p> {{ info.comment }} sur l'affaire <a [routerLink]="['/case-template/', info.caseId._id]" style="text-decoration: underline; color: orangered;" *ngIf="info.customerId != null">{{ info.customerId.name }}</a></p>
        </div>

        <div *ngIf="info.reason && info.reason === 'rappelpiece'">
          <h5 class="mt-3">Rappel</h5>
          <p> {{ info.comment }} sur l'affaire <a [routerLink]="['/commercial-template/', info.caseId._id]" style="text-decoration: underline; color: orangered;" *ngIf="info.customerId != null">{{ info.customerId.name }}</a></p>
        </div>

        <div *ngIf="info.reason && info.reason === 'rappelpieceDA'">
          <h5 class="mt-3">Rappel</h5>
          <p> {{ info.comment }} sur l'affaire <a [routerLink]="['/commercial-template/', info.caseId._id]" style="text-decoration: underline; color: orangered;" *ngIf="info.customerId != null">{{ info.customerId.name }}</a></p>
        </div>

        <div *ngIf="info.reason && info.reason === 'rappelpieceDG'">
          <h5 class="mt-3">Rappel</h5>
          <p> {{ info.comment }} sur l'affaire <a [routerLink]="['/commercial-template/', info.caseId._id]" style="text-decoration: underline; color: orangered;" *ngIf="info.customerId != null">{{ info.customerId.name }}</a></p>
        </div>

        <div *ngIf="info.reason && info.reason === 'recetteAdv'">
          <h5 class="mt-3">Nouvelle pièce</h5>
          <p> {{ info.comment }} sur l'affaire <a [routerLink]="['/case-template/', info.caseId._id]" style="text-decoration: underline; color: orangered;" *ngIf="info.customerId != null">{{ info.customerId.name }}</a></p>
        </div>
        <div *ngIf="info.reason && info.reason === 'bi'">
          <h5 class="mt-3">Nouvelle pièce</h5>
          <p> {{ info.comment }} sur l'affaire <a [routerLink]="['/case-template/', info.caseId._id]" style="text-decoration: underline; color: orangered;" *ngIf="info.customerId != null">{{ info.customerId.name }}</a></p>
        </div>
        <div *ngIf="info.reason && info.reason === 'messageClient'">
          <h5 class="mt-3">{{ info.customerId.name }}</h5>
          <p> {{ info.comment }} <a [routerLink]="['/case-template/', info.caseId._id]" style="text-decoration: underline; color: orangered;" *ngIf="info.customerId != null">{{ info.customerId.name }}</a></p>
        </div>
        <div *ngIf="info.reason && info.reason === 'bi'">
          <h5 class="mt-3">Nouvelle pièce</h5>
          <p> {{ info.comment }} sur l'affaire <a [routerLink]="['/commercial-template/', info.caseId._id]" style="text-decoration: underline; color: orangered;" *ngIf="info.customerId != null">{{ info.customerId.name }}</a></p>
        </div>
        <div *ngIf="info.reason && info.reason === 'cm_birthday'">
          <h5 class="mt-3">Relance CM </h5>
           <p><a [routerLink]="['/commercial-template/', info.caseId._id]" style="text-decoration: underline; color: orangered;" *ngIf="info.customerId != null">{{info.comment}}</a></p>
        </div>
        <div *ngIf="info.reason && info.reason === 'bdc_conforme'">
          <h5 class="mt-3">Bon de commande conforme</h5>
          <p>{{info.comment}} pour le client <a [routerLink]="['/case-template/', info.caseId._id]" style="text-decoration: underline; color: orangered;" *ngIf="info.customerId != null">{{ info.customerId.name }}</a></p>
        </div>
        <div *ngIf="info.reason && info.reason === 'dossier_prime'">
          <h5 class="mt-3">Dossier MPR</h5>
          <p>{{info.comment}} pour le client <a [routerLink]="['/case-template/', info.caseId._id]" style="text-decoration: underline; color: orangered;" *ngIf="info.customerId != null">{{ info.customerId.name }}</a></p>
        </div>

<div class="wrapper">
  <div class="icon">
      <div class="tooltip">Marquer comme lu</div>
      <p (click)="saveChange(info,info._id)" class="dot"></p>
  </div>
</div>
      </div>
        <!-- <p (click)="deleteInfo(info._id)">x</p> -->
    </div>

  <div *ngIf='showRead'>
    <h4 class="mt-3">Lues ({{ infosRead.length }})</h4>
    <div *ngFor="let info of infosRead">
      <div class="card custom-row row d-flex">
        <div *ngIf="info.reason && info.reason === 'pieces'">
          <h5 class="mt-3">{{ info.created_by.username }}</h5>
          <p> à ajouté la pièce {{ info.comment }} le {{ info.created_at | date:'medium' }} pour le client <a (click)="goToCase(info.caseId._id,info,info._id)" style="text-decoration: underline; color: orangered;" *ngIf="info.customerId != null"> {{ info.customerId.name }} </a></p>
        </div>
        <div *ngIf="info.reason && info.reason === 'commentaire'">
          <h5 class="mt-3">{{ info.created_by.username }}</h5>
           <p> vous a mentionné sur un commentaire le {{ info.created_at | date:'medium' }} sur l'affaire de <a (click)="goToCase(info.caseId._id,info,info._id)" style="text-decoration: underline; color: orangered;" *ngIf="info.customerId != null">{{ info.customerId.name }}</a></p>
        </div>
        <div *ngIf="info.reason && info.reason === 'like'">
          <h5 class="mt-3">{{ info.created_by.username }}</h5>
           <p>{{info.comment}} votre commentaire le {{ info.created_at | date:'medium' }} sur l'affaire de <a (click)="goToCase(info.caseId._id,info,info._id)" style="text-decoration: underline; color: orangered;" *ngIf="info.customerId != null">{{ info.customerId.name }}</a></p>
        </div>
        <div *ngIf="info.reason && info.reason === 'signée'">
          <h5 class="mt-3">{{ info.created_by.username }}</h5>
          <p> à signé avec le client <a (click)="goToCase(info.caseId._id,info,info._id)" style="text-decoration: underline; color: orangered;" *ngIf="info.customerId != null">{{ info.customerId.name }}</a> </p>
        </div>
        <div *ngIf="info.reason && info.reason === 'qualification'">
          <h5 class="mt-3">{{ info.created_by.username }}</h5>
          <p>à qualifé une pièce non conforme sur l'affaire de <a (click)="goToCase(info.caseId._id,info,info._id)" style="text-decoration: underline; color: orangered;" *ngIf="info.customerId != null">{{ info.customerId.name }}</a> </p>
        </div>
        <div *ngIf="info.reason && info.reason === 'rappelsign'">
          <h5 class="mt-3">Rappel</h5>
          <p>{{ info.comment }} <a (click)="goToCase(info.caseId._id,info,info._id)" style="text-decoration: underline; color: orangered;" *ngIf="info.customerId != null">{{ info.customerId.name }}</a> </p>
        </div>
        <div *ngIf="info.reason && info.reason === 'rappelsignDA'">
          <h5 class="mt-3">Rappel</h5>
          <p>{{ info.comment }} <a (click)="goToCase(info.caseId._id,info,info._id)" style="text-decoration: underline; color: orangered;" *ngIf="info.customerId != null">{{ info.customerId.name }}</a> </p>
        </div>
        <div *ngIf="info.reason && info.reason === 'rappelprospection'">
          <h5 class="mt-3">Rappel</h5>
          <p>{{ info.comment }} <a (click)="goToCase(info.caseId._id,info,info._id)" style="text-decoration: underline; color: orangered;" *ngIf="info.customerId != null">{{ info.customerId.name }}</a> </p>
        </div>
        <div *ngIf="info.reason && info.reason === 'annulée'">
          <h5 class="mt-3">Affaire annulée</h5>
          <p>L'affaire de <a (click)="goToCase(info.caseId._id,info,info._id)" style="text-decoration: underline; color: orangered;" *ngIf="info.customerId != null">{{ info.customerId.name }}</a> est passée en {{ info.comment }}  </p>
        </div>
        <div *ngIf="info.reason && info.reason === 'chantier'">
          <h5 class="mt-3">{{ info.created_by.username }}</h5>
          <p>a changé le {{ info.comment }} sur l'affaire <a (click)="goToCase(info.caseId._id,info,info._id)" style="text-decoration: underline; color: orangered;" *ngIf="info.customerId != null">{{ info.customerId.name }}</a></p>
        </div>
        <div *ngIf="info.reason && info.reason === 'valid_tarif'">
          <h5 class="mt-3">{{ info.created_by.username }}</h5>
          <p> {{ info.comment }} sur l'affaire <a (click)="goToCase(info.caseId._id,info,info._id)" style="text-decoration: underline; color: orangered;" *ngIf="info.customerId != null">{{ info.customerId.name }}</a></p>
        </div>
        <div *ngIf="info.reason && info.reason === 'facture'">
          <h5 class="mt-3">{{ info.created_by.username }}</h5>
          <p> Facturation de l'affaire <a (click)="goToCase(info.caseId._id,info,info._id)" style="text-decoration: underline; color: orangered;" *ngIf="info.customerId != null">{{ info.customerId.name }}</a></p>
        </div>
        <div *ngIf="info.reason && info.reason === 'piece_manquante'">
          <h5 class="mt-3">{{ info.created_by.username }}</h5>
          <p> {{ info.comment }} sur l'affaire <a [routerLink]="['/commercial-template/', info.caseId._id]" style="text-decoration: underline; color: orangered;" *ngIf="info.customerId != null">{{ info.customerId.name }}</a></p>
        </div>

        <div *ngIf="info.reason && info.reason === 'task_gdd'">
          <h5 class="mt-3">{{ info.created_by.username }}</h5>
          <p> {{ info.comment }} le {{ info.created_at | date:'medium' }} sur l'affaire <a [routerLink]="['/commercial-template/', info.caseId._id]" style="text-decoration: underline; color: orangered;" *ngIf="info.customerId != null">{{ info.customerId.name }}</a></p>
        </div>

        <div *ngIf="info.reason && info.reason === 'rappelmairie'">
          <h5 class="mt-3">Rappel</h5>
          <p> {{ info.comment }} sur l'affaire <a [routerLink]="['/commercial-template/', info.caseId._id]" style="text-decoration: underline; color: orangered;" *ngIf="info.customerId != null">{{ info.customerId.name }}</a></p>
        </div>
        <div *ngIf="info.reason && info.reason === 'rappelfi'">
          <h5 class="mt-3">Rappel</h5>
          <p> {{ info.comment }} sur l'affaire <a [routerLink]="['/commercial-template/', info.caseId._id]" style="text-decoration: underline; color: orangered;" *ngIf="info.customerId != null">{{ info.customerId.name }}</a></p>
        </div>

        <div *ngIf="info.reason && info.reason === 'rappelpiece'">
          <h5 class="mt-3">Rappel</h5>
          <p> {{ info.comment }} sur l'affaire <a [routerLink]="['/commercial-template/', info.caseId._id]" style="text-decoration: underline; color: orangered;" *ngIf="info.customerId != null">{{ info.customerId.name }}</a></p>
        </div>

        <div *ngIf="info.reason && info.reason === 'rappelpieceDA'">
          <h5 class="mt-3">Rappel</h5>
          <p> {{ info.comment }} sur l'affaire <a [routerLink]="['/commercial-template/', info.caseId._id]" style="text-decoration: underline; color: orangered;" *ngIf="info.customerId != null">{{ info.customerId.name }}</a></p>
        </div>

        <div *ngIf="info.reason && info.reason === 'rappelpieceDG'">
          <h5 class="mt-3">Rappel</h5>
          <p> {{ info.comment }} sur l'affaire <a [routerLink]="['/commercial-template/', info.caseId._id]" style="text-decoration: underline; color: orangered;" *ngIf="info.customerId != null">{{ info.customerId.name }}</a></p>
        </div>

        <div *ngIf="info.reason && info.reason === 'recetteAdv'">
          <h5 class="mt-3">Nouvelle pièce</h5>
          <p> {{ info.comment }} sur l'affaire <a [routerLink]="['/case-template/', info.caseId._id]" style="text-decoration: underline; color: orangered;" *ngIf="info.customerId != null">{{ info.customerId.name }}</a></p>
        </div>
        <div *ngIf="info.reason && info.reason === 'bi'">
          <h5 class="mt-3">Nouvelle pièce</h5>
          <p> {{ info.comment }} sur l'affaire <a [routerLink]="['/case-template/', info.caseId._id]" style="text-decoration: underline; color: orangered;" *ngIf="info.customerId != null">{{ info.customerId.name }}</a></p>
        </div>
        <div *ngIf="info.reason && info.reason === 'messageClient'">
          <h5 class="mt-3">{{ info.customerId.name }}</h5>
          <p> {{ info.comment }} <a [routerLink]="['/case-template/', info.caseId._id]" style="text-decoration: underline; color: orangered;" *ngIf="info.customerId != null">{{ info.customerId.name }}</a></p>
        </div>
        <div *ngIf="info.reason && info.reason === 'bi'">
          <h5 class="mt-3">Nouvelle pièce</h5>
          <p> {{ info.comment }} sur l'affaire <a [routerLink]="['/commercial-template/', info.caseId._id]" style="text-decoration: underline; color: orangered;" *ngIf="info.customerId != null">{{ info.customerId.name }}</a></p>
        </div>

        <div *ngIf="info.reason && info.reason === 'cm_birthday'">
          <h5 class="mt-3">Relance CM </h5>
           <p><a [routerLink]="['/commercial-template/', info.caseId._id]" style="text-decoration: underline; color: orangered;" *ngIf="info.customerId != null">{{info.comment}}</a></p>
        </div>
        <div *ngIf="info.reason && info.reason === 'bdc_conforme'">
          <h5 class="mt-3">Bon de commande conforme</h5>
          <p>{{info.comment}} pour le client <a [routerLink]="['/case-template/', info.caseId._id]" style="text-decoration: underline; color: orangered;" *ngIf="info.customerId != null">{{ info.customerId.name }}</a></p>
        </div>
        <div *ngIf="info.reason && info.reason === 'dossier_prime'">
          <h5 class="mt-3">Dossier MPR</h5>
          <p>{{info.comment}} pour le client <a [routerLink]="['/case-template/', info.caseId._id]" style="text-decoration: underline; color: orangered;" *ngIf="info.customerId != null">{{ info.customerId.name }}</a></p>
        </div>
        
        <p (click)="deleteInfo(info._id)" class="dot-check">x</p>
      </div>

    </div>
  </div>
  </div>

  </div>
</div>

<app-favorite *ngIf="!mobile && isAdmin || isAdv "></app-favorite>


<img *ngIf="this.sRoles.userRole != 'Responsable Technicien' && this.sRoles.userRole != 'Technicien'" class="notification" [routerLink]="['/infos-advs']" src="../../../assets/icons/notification.svg">
<p *ngIf="this.sRoles.userRole != 'Responsable Technicien' && this.sRoles.userRole != 'Technicien'" class="compteur">
  <span matBadge="{{ infosUnread.length }}" matBadgeSize="small"></span>
</p>
<img *ngIf="this.sRoles.userRole === 'Responsable Technicien' || this.sRoles.userRole === 'Technicien'" class="notification-tech" [routerLink]="['/infos-advs']" src="../../../assets/icons/notification.svg">
<p *ngIf="this.sRoles.userRole === 'Responsable Technicien' || this.sRoles.userRole === 'Technicien'" class="compteur-tech">
  <span matBadge="{{ infosUnread.length }}" matBadgeSize="small"></span>
</p>
<div class="popup-notif">

<div class="row custom-row">
  <button (click)="showUnreadNotif()"  class="btn-secondarycrm col-4  offset-2" [ngClass]=" !showRead ? 'actived' : 'disabled'">Non lues ({{ infosUnread.length }})</button>
  <button (click)="showAll()" class="btn-secondarycrm col-4" [ngClass]="showRead  ? 'actived' : 'disabled'">Toutes ({{ infosUnread.length + infosRead.length }})</button>

</div>

<div class="row custom-row content-infos" *ngIf="showUnread || showRead">
  <div class="scroll col-12" id="scroll">
    <div *ngFor="let info of infosUnread">
      <div class="card row custom-row d-flex">
        <div *ngIf="info.reason && info.reason === 'pieces'">
          <h5 class="mt-3">{{ info.created_by.username }}</h5>
          <p> à ajouté la pièce {{ info.comment }} le {{ info.created_at | date:'medium' }} pour le client <a (click)="goToCase(info.caseId._id,info,info._id)" style="text-decoration: underline; color: orangered;" *ngIf="info.customerId != null">{{ info.customerId.name }}</a> </p>
        </div>
        <div *ngIf="info.reason && info.reason === 'commentaire'">
          <h5 class="mt-3">{{ info.created_by.username }}</h5>
           <p>vous a mentionné sur un commentaire le {{ info.created_at | date:'medium' }} sur l'affaire de <a (click)="goToCase(info.caseId._id,info,info._id)" style="text-decoration: underline; color: orangered;" *ngIf="info.customerId != null">{{ info.customerId.name }}</a></p>
        </div>
        <div *ngIf="info.reason && info.reason === 'like'">
          <h5 class="mt-3">{{ info.created_by.username }}</h5>
           <p>{{info.comment}} votre commentaire le {{ info.created_at | date:'medium' }} sur l'affaire de <a (click)="goToCase(info.caseId._id,info,info._id)" style="text-decoration: underline; color: orangered;" *ngIf="info.customerId != null">{{ info.customerId.name }}</a></p>
        </div>
        <div *ngIf="info.reason && info.reason === 'signée'">
          <h5 class="mt-3">{{ info.created_by.username }}</h5>
          <p> à signé avec le client <a (click)="goToCase(info.caseId._id,info,info._id)" style="text-decoration: underline; color: orangered;" *ngIf="info.customerId != null">{{ info.customerId.name }}</a> </p>
        </div>
        <div *ngIf="info.reason && info.reason === 'qualification'">
          <h5 class="mt-3">{{ info.created_by.username }}</h5>
          <p>à qualifé une pièce non conforme sur l'affaire de <a (click)="goToCase(info.caseId._id,info,info._id)" style="text-decoration: underline; color: orangered;" *ngIf="info.customerId != null">{{ info.customerId.name }}</a>
            le {{ info.created_at | date:'medium' }} </p>
        </div>
        <div *ngIf="info.reason && info.reason === 'rappelsign'">
          <h5 class="mt-3">Rappel</h5>
          <p>{{ info.comment }} <a (click)="goToCase(info.caseId._id,info,info._id)" style="text-decoration: underline; color: orangered;" *ngIf="info.customerId != null">{{ info.customerId.name }}</a> </p>
        </div>
        <div *ngIf="info.reason && info.reason === 'rappelsignDA'">
          <h5 class="mt-3">Rappel</h5>
          <p>{{ info.comment }} <a (click)="goToCase(info.caseId._id,info,info._id)" style="text-decoration: underline; color: orangered;" *ngIf="info.customerId != null">{{ info.customerId.name }}</a> </p>
        </div>
        <div *ngIf="info.reason && info.reason === 'rappelprospection'">
          <h5 class="mt-3">Rappel</h5>
          <p>{{ info.comment }} <a (click)="goToCase(info.caseId._id,info,info._id)" style="text-decoration: underline; color: orangered;" *ngIf="info.customerId != null">{{ info.customerId.name }}</a> </p>
        </div>
        <div *ngIf="info.reason && info.reason === 'annulée'">
          <h5 class="mt-3">Affaire annulée</h5>
          <p>L'affaire de <a (click)="goToCase(info.caseId._id,info,info._id)" style="text-decoration: underline; color: orangered;" *ngIf="info.customerId != null">{{ info.customerId.name }}</a> est passée en {{ info.comment }}  </p>
        </div>
        <div *ngIf="info.reason && info.reason === 'chantier'">
          <h5 class="mt-3">{{ info.created_by.username }}</h5>
          <p>a changé le {{ info.comment }} sur l'affaire <a (click)="goToCase(info.caseId._id,info,info._id)" style="text-decoration: underline; color: orangered;" *ngIf="info.customerId != null">{{ info.customerId.name }}</a></p>
        </div>
        <div *ngIf="info.reason && info.reason === 'facture'">
          <h5 class="mt-3">{{ info.created_by.username }}</h5>
          <p> Facturation de l'affaire <a (click)="goToCase(info.caseId._id,info,info._id)" style="text-decoration: underline; color: orangered;" *ngIf="info.customerId != null">{{ info.customerId.name }}</a></p>
        </div>
        <div *ngIf="info.reason && info.reason === 'piece_manquante'">
          <h5 class="mt-3">{{ info.created_by.username }}</h5>
          <p> {{ info.comment }} sur l'affaire <a [routerLink]="['/commercial-template/', info.caseId._id]" style="text-decoration: underline; color: orangered;" *ngIf="info.customerId != null">{{ info.customerId.name }}</a></p>
        </div>
        <div *ngIf="info.reason && info.reason === 'task_gdd'">
          <h5 class="mt-3">{{ info.created_by.username }} <mat-icon (click)="deleteInfo(info._id)" class="trash">delete_forever</mat-icon> </h5>
           <p>{{info.comment}} le {{ info.created_at | date:'medium' }} sur l'affaire de <a (click)="goToCase(info.caseId._id,info,info._id)" style="text-decoration: underline; color: orangered;" *ngIf="info.customerId != null">{{ info.customerId.name }}</a></p>
        </div>

        <div *ngIf="info.reason && info.reason === 'recette'">
           <p>{{info.comment}} le {{ info.created_at | date:'medium' }} sur l'affaire de <a (click)="goToChantier(info,info._id)" style="text-decoration: underline; color: orangered;" *ngIf="info.customerId != null">{{ info.customerId.name }}</a></p>
        </div>

        <div *ngIf="info.reason && info.reason === 'rappelpiece'">
          <h5 class="mt-3">Rappel</h5>
          <p>{{ info.comment }} <a *ngIf="info.caseId && info.customerId" [routerLink]="['/commercial-template/', info.caseId._id]" style="text-decoration: underline; color: orangered;font-size: 0.8rem;">{{ info.customerId?.name }}</a> </p>
        </div>
        <div *ngIf="info.reason && info.reason === 'rappelpieceDA'">
          <h5 class="mt-3">Rappel</h5>
          <p>{{ info.comment }} <a *ngIf="info.caseId && info.customerId" [routerLink]="['/commercial-template/', info.caseId._id]" style="text-decoration: underline; color: orangered;font-size: 0.8rem;">{{ info.customerId?.name }}</a> </p>
        </div>
        <div *ngIf="info.reason && info.reason === 'rappelpieceDG'">
          <h5 class="mt-3">Rappel</h5>
          <p>{{ info.comment }} <a *ngIf="info.caseId && info.customerId" [routerLink]="['/commercial-template/', info.caseId._id]" style="text-decoration: underline; color: orangered;font-size: 0.8rem;">{{ info.customerId?.name }}</a> </p>
        </div>
        <div *ngIf="info.reason && info.reason === 'recetteAdv'">
          <h5 class="mt-3">Nouvelle pièce </h5>
           <p>{{info.comment}} sur l'affaire de <a (click)="goToCase(info.caseId._id,info,info._id)" style="text-decoration: underline; color: orangered;">{{ info.customerId.name }}</a></p>
        </div>
        <div *ngIf="info.reason && info.reason === 'bi'">
          <h5 class="mt-3">Nouvelle pièce </h5>
           <p>{{info.comment}} sur l'affaire de <a (click)="goToCase(info.caseId._id,info,info._id)" style="text-decoration: underline; color: orangered;">{{ info.customerId.name }}</a></p>
        </div>
        <div *ngIf="info.reason && info.reason === 'cm_birthday'">
          <h5 class="mt-3">Relance CM </h5>
           <p><a (click)="goToCase(info.caseId._id,info,info._id)" style="text-decoration: underline; color: orangered;">{{info.comment}}</a></p>
        </div>
<div class="wrapper">
  <div class="icon">
      <div class="tooltip">Marquer comme lu</div>
      <p (click)="saveChange(info,info._id)" class="dot"></p>
  </div>
</div>
      </div>
    </div>

  <div *ngIf='showRead'>
    <h4 class="mt-3">Lues ({{ infosRead.length }})</h4>
    <div *ngFor="let info of infosRead">
      <div class="card custom-row row d-flex">
        <div *ngIf="info.reason && info.reason === 'pieces'">
          <h5 class="mt-3">{{ info.created_by.username }}</h5>
          <p> à ajouté la pièce {{ info.comment }} le {{ info.created_at | date:'medium' }} pour le client <a (click)="goToCase(info.caseId._id,info,info._id)" style="text-decoration: underline; color: orangered;" *ngIf="info.customerId != null"> {{ info.customerId.name }} </a></p>
        </div>
        <div *ngIf="info.reason && info.reason === 'commentaire'">
          <h5 class="mt-3">{{ info.created_by.username }}</h5>
           <p> vous a mentionné sur un commentaire le {{ info.created_at | date:'medium' }} sur l'affaire de <a (click)="goToCase(info.caseId._id,info,info._id)" style="text-decoration: underline; color: orangered;" *ngIf="info.customerId != null">{{ info.customerId.name }}</a></p>
        </div>
        <div *ngIf="info.reason && info.reason === 'like'">
          <h5 class="mt-3">{{ info.created_by.username }}</h5>
           <p>{{info.comment}} votre commentaire le {{ info.created_at | date:'medium' }} sur l'affaire de <a (click)="goToCase(info.caseId._id,info,info._id)" style="text-decoration: underline; color: orangered;" *ngIf="info.customerId != null">{{ info.customerId.name }}</a></p>
        </div>
        <div *ngIf="info.reason && info.reason === 'signée'">
          <h5 class="mt-3">{{ info.created_by.username }}</h5>
          <p> à signé avec le client <a (click)="goToCase(info.caseId._id,info,info._id)" style="text-decoration: underline; color: orangered;" *ngIf="info.customerId != null">{{ info.customerId.name }}</a> </p>
        </div>
        <div *ngIf="info.reason && info.reason === 'qualification'">
          <h5 class="mt-3">{{ info.created_by.username }}</h5>
          <p>à qualifé une pièce non conforme sur l'affaire de <a (click)="goToCase(info.caseId._id,info,info._id)" style="text-decoration: underline; color: orangered;" *ngIf="info.customerId != null">{{ info.customerId.name }}</a> </p>
        </div>
        <div *ngIf="info.reason && info.reason === 'rappelsign'">
          <h5 class="mt-3">Rappel</h5>
          <p>{{ info.comment }} <a (click)="goToCase(info.caseId._id,info,info._id)" style="text-decoration: underline; color: orangered;" *ngIf="info.customerId != null">{{ info.customerId.name }}</a> </p>
        </div>
        <div *ngIf="info.reason && info.reason === 'rappelsignDA'">
          <h5 class="mt-3">Rappel</h5>
          <p>{{ info.comment }} <a (click)="goToCase(info.caseId._id,info,info._id)" style="text-decoration: underline; color: orangered;" *ngIf="info.customerId != null">{{ info.customerId.name }}</a> </p>
        </div>
        <div *ngIf="info.reason && info.reason === 'rappelprospection'">
          <h5 class="mt-3">Rappel</h5>
          <p>{{ info.comment }} <a (click)="goToCase(info.caseId._id,info,info._id)" style="text-decoration: underline; color: orangered;" *ngIf="info.customerId != null">{{ info.customerId.name }}</a> </p>
        </div>
        <div *ngIf="info.reason && info.reason === 'annulée'">
          <h5 class="mt-3">Affaire annulée</h5>
          <p>L'affaire de <a (click)="goToCase(info.caseId._id,info,info._id)" style="text-decoration: underline; color: orangered;" *ngIf="info.customerId != null">{{ info.customerId.name }}</a> est passée en {{ info.comment }}  </p>
        </div>
        <div *ngIf="info.reason && info.reason === 'chantier'">
          <h5 class="mt-3">{{ info.created_by.username }}</h5>
          <p>a changé le {{ info.comment }} sur l'affaire <a (click)="goToCase(info.caseId._id,info,info._id)" style="text-decoration: underline; color: orangered;" *ngIf="info.customerId != null">{{ info.customerId.name }}</a></p>
        </div>
        <div *ngIf="info.reason && info.reason === 'facture'">
          <h5 class="mt-3">{{ info.created_by.username }}</h5>
          <p> Facturation de l'affaire <a (click)="goToCase(info.caseId._id,info,info._id)" style="text-decoration: underline; color: orangered;" *ngIf="info.customerId != null">{{ info.customerId.name }}</a></p>
        </div>
        <div *ngIf="info.reason && info.reason === 'piece_manquante'">
          <h5 class="mt-3">{{ info.created_by.username }}</h5>
          <p> {{ info.comment }} sur l'affaire <a [routerLink]="['/commercial-template/', info.caseId._id]" style="text-decoration: underline; color: orangered;" *ngIf="info.customerId != null">{{ info.customerId.name }}</a></p>
        </div>
        <div *ngIf="info.reason && info.reason === 'task_gdd'">
          <h5 class="mt-3">{{ info.created_by.username }} <mat-icon (click)="deleteInfo(info._id)" class="trash">delete_forever</mat-icon> </h5>
           <p>{{info.comment}} le {{ info.created_at | date:'medium' }} sur l'affaire de <a (click)="goToCase(info.caseId._id,info,info._id)" style="text-decoration: underline; color: orangered;" *ngIf="info.customerId != null">{{ info.customerId.name }}</a></p>
        </div>

        <div *ngIf="info.reason && info.reason === 'recette'">
          <h5 class="mt-3">{{ info.created_by.username }} <mat-icon (click)="deleteInfo(info._id)" class="trash">delete_forever</mat-icon> </h5>
           <p>{{info.comment}} le {{ info.created_at | date:'medium' }} sur l'affaire de <a (click)="goToCase(info.caseId._id,info,info._id)" style="text-decoration: underline; color: orangered;" *ngIf="info.customerId != null">{{ info.customerId.name }}</a></p>
        </div>

        <div *ngIf="info.reason && info.reason === 'rappelpiece'">
          <h5 class="mt-3">Rappel</h5>
          <p>{{ info.comment }} <a (click)="goToCase(info.caseId._id,info,info._id)" style="text-decoration: underline; color: orangered;" *ngIf="info.customerId != null">{{ info.customerId.name }}</a> </p>
        </div>

        <div *ngIf="info.reason && info.reason === 'rappelpieceDA'">
          <h5 class="mt-3">Rappel</h5>
          <p>{{ info.comment }} <a (click)="goToCase(info.caseId._id,info,info._id)" style="text-decoration: underline; color: orangered;" *ngIf="info.customerId != null">{{ info.customerId.name }}</a> </p>
        </div>

        <div *ngIf="info.reason && info.reason === 'rappelpieceDG'">
          <h5 class="mt-3">Rappel</h5>
          <p>{{ info.comment }} <a (click)="goToCase(info.caseId._id,info,info._id)" style="text-decoration: underline; color: orangered;" *ngIf="info.customerId != null">{{ info.customerId.name }}</a> </p>
        </div>

        <div *ngIf="info.reason && info.reason === 'cm_birthday'">
          <h5 class="mt-3">Relance CM </h5>
           <p><a (click)="goToCase(info.caseId._id,info,info._id)" style="text-decoration: underline; color: orangered;">{{info.comment}}</a></p>
        </div>

        <div *ngIf="info.reason && info.reason === 'bdc_conforme'">
          <h5 class="mt-3">Bon de commande conforme</h5>
           <p>{{info.comment}} pour le client <a (click)="goToCase(info.caseId._id,info,info._id)" style="text-decoration: underline; color: orangered;">{{ info.customerId.name }}</a></p>
        </div>
        <p (click)="deleteInfo(info._id)" class="dot-check">x</p>
      </div>



    </div>
  </div>
  </div>

  </div>
</div>

import { AuthenticationService } from './../../_services/authentication.service';
import { Component, OnInit } from '@angular/core';
import { CasesService } from '@app/services/cases.service';

@Component({
  selector: 'app-super-tmk-dashboard',
  templateUrl: './super-tmk-dashboard.component.html',
  styleUrls: ['./super-tmk-dashboard.component.scss']
})
export class SuperTmkDashboardComponent implements OnInit {

  currentUser;
  cases;
  aConfirme = []
  label_aConfirme
  pris
  label_pris
  confirme
  label_confirme
  rdvPris;
  label_rdvPris;
  aRepos
  label_aRepos
  annulation
  label_annulation
  arepo
  label_arepo
  label_signe = "Be energie"
  signeBeenergie = []
  loading = true
  constructor(
    private sCases: CasesService,
    private authenticationService: AuthenticationService,
  ) {
    this.authenticationService.user.subscribe(x => this.currentUser = x);
   }

  ngOnInit(): void {
    this.showAllTmkCases()
  }

  showAllTmkCases() {
    this.aConfirme = []
    let allCases = []
    this.sCases.getAllTmkCases().subscribe(res => {
       allCases = res['cases'];

       allCases.forEach(element => {
         if(new Date().getDay() === 1) {
          if(new Date(element.r1[0].date) > new Date() && new Date(element.r1[0].date).getDay() === 2) {
            console.log('pour mardi')
            this.aConfirme.push(element)
          }
         }
         else if(new Date().getDay() === 2) {
          if(new Date(element.r1[0].date) > new Date() && new Date(element.r1[0].date).getDay() === 3) {
            console.log('pour mercredi')
            this.aConfirme.push(element)
          }
         }
         else if(new Date().getDay() === 3) {
          if(new Date(element.r1[0].date) > new Date() && new Date(element.r1[0].date).getDay() === 4) {
            console.log('pour jeudi')
            this.aConfirme.push(element)
          }
         }
         else if(new Date().getDay() === 4) {
          if(new Date(element.r1[0].date) > new Date() && new Date(element.r1[0].date).getDay() === 5) {
            console.log('pour vendredi')
            this.aConfirme.push(element)
          }
         }
         // VENDREDI
        else if(new Date().getDay() === 5) {
          if(new Date(element.r1[0].date) > new Date() && new Date(element.r1[0].date).getDay() === 6 || new Date(element.r1[0].date) > new Date() && new Date(element.r1[0].date).getDay() === 1) {
            console.log('pour samedi et lundi')
            this.aConfirme.push(element)
          }
        }
      });

      this.aConfirme.sort((d1, d2) => new Date(d1.datecase).getTime() - new Date(d2.datecase).getTime());
      this.label_aConfirme = 'A confirmer (' + this.aConfirme.length + ')'

      this.rdvPris = allCases.filter(c => c.stateTmk != "Confirmé" && c.commercial === null).sort((d1, d2) => new Date(d1.r1[0].date).getTime() - new Date(d2.r1[0].date).getTime());
      this.rdvPris = this.rdvPris.filter(val => !this.aConfirme.includes(val));

      this.label_rdvPris = 'Rdv pris (' + this.rdvPris.length + ')'

      this.confirme = allCases.filter(c => c.stateTmk === "Confirmé" && c.commercial != null).sort((d1, d2) => new Date(d2.r1[0].date).getTime() - new Date(d1.r1[0].date).getTime());
      this.label_confirme = 'Confirmé (' + this.confirme.length + ')'

      this.annulation = allCases.filter(c => c.stateTmk === "Non-conforme")
      this.label_annulation = 'Annulation (' + this.annulation.length + ')'

      this.arepo = allCases.filter(c => c.stateTmk === "A repositionner")
      this.label_arepo = 'A repositionner (' + this.arepo.length + ')'

      this.loading = false
    });
  }

  showSigneBeenergie() {
    this.sCases.getCaseByCancelState().subscribe(res => {
      this.signeBeenergie = res['cases'];
      this.signeBeenergie = this.signeBeenergie.filter(c => c.customer.origin === 'Be energie' && c.type != 'Contrat de maintenance').sort((a, b) => a.datesign > b.datesign ? -1 : (a.datesign < b.datesign ? 1 : 0)).sort((a, b) => a.state > b.state ? -1 : (a.state < b.state ? 1 : 0))
      this.label_signe = 'Be energie (' + this.signeBeenergie.length + ')'

    })
  }

  tabClick(tab) {
    if(tab.tab.textLabel === 'Be energie')
    this.showSigneBeenergie()
  }
  deleteCase(id) {
    if ( confirm( "Confirmer la suppression de l'affaire ? " ) ) {
      this.sCases.delete(id).subscribe(response => {
        console.log("Suppression effectuée");
        this.showAllTmkCases()
      });
    }
  }
}

import { Location } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { CasesService } from '@app/services/cases.service';
import { HistoriqueService } from '@app/services/historique.service';
import { InfosService } from '@app/services/infos.service';
import { PiecesService } from '@app/services/pieces.service';
import { RolesService } from '@app/services/roles.service';
import { EditPieceComponent } from '../edit-piece/edit-piece.component';
import { apiUrl } from '@environments/env';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-detail-piece',
  templateUrl: './detail-piece.component.html',
  styleUrls: ['./detail-piece.component.scss']
})
export class DetailPieceComponent implements OnInit {
  @Input() currentPiece: string;
  userRole;
  caseId;
  case;
  pieceId;
  detail = true;
  mobile;
  dateNow;
  pieceForm: FormGroup;
  piece = {
    title: '',
    image: '',
    caseId: '',
    description: '',
    created_at: '',
    qualification: '',
    commentary: '',
  };
  arrayImg = [];
  pdfImg = "https://upload.wikimedia.org/wikipedia/commons/8/87/PDF_file_icon.svg";

  qualifications = ['Conforme', 'En attente de qualification', 'Non conforme', 'Manquante'];
  multipleImagesPieces;

  apiUrl = apiUrl.apiUrl + '/piece/';
  pieceSplit = []
  constructor(
    private sPieces: PiecesService,
    private dialog: MatDialog,
    private router: Router,
    private sRoles: RolesService,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private sInfos: InfosService,
    private sCases: CasesService,
    private sHistoriques: HistoriqueService,
    private location: Location,
    private http: HttpClient,
    private sb: MatSnackBar,
  ) {
    this.pieceForm = this.fb.group({
      "qualification": [],
      "commentary": '',
    });
  }

  showModal: boolean;
  show() {
    this.showModal = true;
  }

  hide() {
    this.showModal = false;
  }

  ngOnInit(): void {
    this.pieceId = this.route.snapshot.params['pieceId'];
    this.userRole = this.sRoles.user.role;
    if (this.userRole == 'Technicien' || this.userRole == 'Responsable Technicien' || this.userRole === 'Compta' || this.userRole === 'Logistique') {
      this.currentPiece = this.pieceId;
      this.showTechPieceById()
    } else {
      this.showPieceById();
    }

    if (window.screen.width <= 600) {
      this.mobile = true;
    }
    let now = new Date();
    this.dateNow = now.setHours(now.getHours())
  }

  retour() {
    if (this.userRole === 'Adv' || this.userRole === 'Admin') {
      this.router.navigate(['case-template', this.piece.caseId], { state: { value: 'piecesList' } });
    }
    else if (this.userRole === 'Commercial' || this.userRole === 'Responsable d\'agence') {
      if (this.mobile) {
        this.router.navigate(['commercial-template', this.piece.caseId]);
      }
      else {
        this.router.navigate(['commercial-template', this.piece.caseId], { state: { value: 'piecesList' } });
      }
    } else if (this.userRole == 'Technicien' || this.userRole == "Responsable Technicien" || this.userRole == 'Compta' || this.userRole === 'Logistique') {
      this.location.back()
    }

  }

  showTechPieceById() {
    this.sPieces.getPieceById(this.pieceId).subscribe(res => {
      this.piece = res[0];
      this.caseId = res[0].caseId;
      this.showCaseById(this.caseId);

      // Je récupère le string et je le passe en array pour pouvoir boucler sur toutes les photos
      this.arrayImg = this.piece.image.split(',')
    })
  }

  open() {

    this.pieceSplit = this.piece.image.split(",");
    this.pieceSplit = this.pieceSplit.map(
      (p) => "https://api-crm.s3.eu-west-3.amazonaws.com/" + this.caseId + '/pieces/' + p
    );
    var counter = 0;
    let i = 0;
    for (i = 0; i < this.pieceSplit.length; i++) {
      this.openPhotos(this.pieceSplit[i]);
      counter++;
    }
  }

  openPhotos(uri) {
    var link = document.createElement("a");
    link.download = uri;
    link.href = uri;
    link.target = "_blank"
    link.click();
  }

  showPieceById() {
    this.sPieces.getPieceById(this.currentPiece).subscribe(res => {
      this.piece = res[0];
      this.caseId = res[0].caseId;
      this.showCaseById(this.caseId);

      // Je récupère le string et je le passe en array pour pouvoir boucler sur toutes les photos
      this.arrayImg = this.piece.image.split(',')
    });
  }

  openDialog(): void {
    const dialogRef = this.dialog.open(EditPieceComponent, {
      width: '640px',
      disableClose: true,
      data: {
        pieceId: this.pieceId
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      this.showPieceById();
    });
  }

  saveChange(reason, change, value) {
    this.pieceForm.value.commentary = this.piece.commentary
    this.pieceForm.value.description = this.piece.description
    this.pieceForm.value.qualification = this.piece.qualification
    this.sPieces.editPiece(this.pieceForm.value, this.currentPiece).then(res => {
      if (res['error']) {
        return res.error;
      }
      this.showPieceById();
    });
    this.sHistoriques.createHistorique(this.caseId, this.case.customer._id, reason, change, value, this.sRoles.user.id).then(res => {
      if (res['error']) {
        alert(res);
      }
    });
  }

  showCaseById(caseId) {
    return new Promise(resolve => {

      this.sCases.getCaseById(caseId).subscribe(res => {
        this.case = res[0];
        resolve(res);
      }, error => {
        console.log(error);
      });
    });
  }

  saveChangeAndNotif(reason, change, value) {
    this.pieceForm.value.commentary = this.piece.commentary
    this.pieceForm.value.description = this.piece.description;
    if (this.piece.title === 'Bon de commande' && this.pieceForm.value.qualification === 'Conforme') {
      const comptaIds = ['60bdd1b05847ab0004161018', '64f6e3c62fdd710002a62945']
      comptaIds.forEach(id => {
        const notif = {
          comment: 'Le Bon de commande est conforme',
          reason: 'bdc_conforme',
          created_by: this.sRoles.user.id,
          created_at: this.dateNow,
          caseId: this.piece.caseId,
          customerId: this.case.customer,
          adv: id,
        }
        this.sInfos.createInfo(notif);
      });

    }
    if (this.pieceForm.value.qualification === 'Non conforme') {
      let comment = {
        comment: 'non conforme' + this.pieceForm.value.title,
        reason: 'qualification',
        created_by: this.sRoles.user.id,
        created_at: this.dateNow,
        caseId: this.piece.caseId,
        customerId: this.case.customer,
        adv: this.case.commercial._id,
      }
      this.sInfos.createInfo(comment).then(res => {
        if (res['error']) {
          alert(res.error);
        }
      });
    }
    this.sPieces.editPiece(this.pieceForm.value, this.currentPiece).then(res => {
      if (res['error']) {
        return res.error;
      }
      else {

      }
      this.showPieceById();
    });
    this.sHistoriques.createHistorique(this.caseId, this.case.customer._id, reason, change, value, this.sRoles.user.id).then(res => {
      if (res['error']) {
        alert(res);
      }
    });
  }

  selectMultiple(event, reason, change, value) {
    if (event.target.files.length > 0) {
      this.multipleImagesPieces = event.target.files;
    }
    this.onSubmitMultiple(reason, change, value)
  }

  deletePiece() {
    this.sPieces.delete(this.currentPiece).subscribe(response => {
      console.log("Suppression effectuée");
    });
  }
  onSubmitMultiple(reason, change, value) {

    const formData = new FormData();
    formData.append('title', this.piece.title);
    formData.append('description', this.piece.description);
    formData.append('qualification', "En attente de qualification");
    formData.append('caseId', this.caseId);
    formData.append('created_at', new Date().toString());
    for (let img of this.multipleImagesPieces) {
      formData.append('filesPieces', img);
    }
    this.deletePiece()
    this.sb.open(`La pièce a été correctement complétée`, '', { duration: 2000, verticalPosition: 'bottom', panelClass: ['add-snackbar'] });
    if (this.case.adv != null) {
      let comment = {
        comment: this.pieceForm.value.title,
        reason: "pieces",
        created_by: this.sRoles.user.id,
        created_at: this.pieceForm.value.created_at,
        caseId: this.caseId,
        customerId: this.case.customer,
        adv: this.case.adv,
      }
      this.sInfos.createInfo(comment).then(res => {
        if (res['error']) {
          alert(res.error);
        }
      });
      this.sHistoriques.createHistorique(this.caseId, this.case.customer._id, reason, change, value, this.sRoles.user.id).then(res => {
        if (res['error']) {
          alert(res);
        }
      });
    }

    this.http.post<any>(this.apiUrl + 'multiplePieces', formData).subscribe(
      (res) => {
        if (this.userRole === 'Commercial' || this.userRole === 'Responsable d\'agence') {
          if (this.mobile) {
            this.router.navigate(['commercial-template', this.caseId]);
          }
          else {
            this.router.onSameUrlNavigation = 'reload';
            this.router.navigate(['commercial-template', this.caseId], { state: { value: 'piecesList' } });
          }
        } else if (this.userRole == 'Technicien' || this.userRole == "Responsable Technicien") {
          this.location.back()
        }
        else {
          this.router.onSameUrlNavigation = 'reload';
          this.router.navigate(['case-template', this.caseId], { state: { value: 'piecesList' } });
        }
      },
      (err) => console.log(err)
    );
  }

}

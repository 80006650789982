import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { RolesService } from '@app/services/roles.service';

@Component({
  selector: 'app-choices',
  templateUrl: './choices.component.html',
  styleUrls: ['./choices.component.scss']
})
export class ChoicesComponent implements OnInit {

  case;
  recettePath
  mobile
  constructor(
    @Inject(MAT_DIALOG_DATA) public currentCase: any,
    @Inject(MAT_DIALOG_DATA) public currentKit: any,
    @Inject(MAT_DIALOG_DATA) public chantierId: any,

    private dialogRef: MatDialog,
    private router: Router,
    public sRoles: RolesService,
  ) {
    if (window.screen.width <= 600) {
      this.mobile = true;
    }
    this.case = currentCase.case
    this.currentKit = currentKit.currentKit
    this.chantierId = chantierId.chantierId
    if(this.currentKit != undefined && this.currentKit.state != 'autre') {
      this.checkImg()
    }

   }

  ngOnInit(): void {
   }

  choice(value) {
    switch (value) {
      case 'recette':
        this.router.navigate(['recette-template',this.case._id], { state: { kit: this.currentKit, chantierId: this.chantierId } });
      break;

      case 'livraison':
        this.router.navigate(['livraison-visualize',this.case._id],{ state: { chantierId: this.chantierId } });
      break;

      case 'reception':
        this.router.navigate(['reception-visualize',this.case._id],{ state: { chantierId: this.chantierId } });
      break;
      case 'bon_inter':
        this.router.navigate(['bi-visualize',this.case._id],{ state: { chantierId: this.chantierId } });
      break;
    }
      this.closeModale();
  }

  checkImg() {
    switch (this.currentKit.name) {
      case 'PAC Air/Air':
        this.recettePath = '../../../assets/icons/recette_pac.svg'
        break;
      case 'PAC Air/Eau':
        this.recettePath = '../../../assets/icons/recette_pac.svg'
        break;

      case 'Ballon thermodynamique':
        this.recettePath = '../../../assets/icons/recette_bt.svg'
        break;

      case 'Panneaux solaires':
        this.recettePath = '../../../assets/icons/recette_pv.svg'
        break;

      case 'Adoucisseur d\'eau':
        this.recettePath = '../../../assets/icons/recette_adoucisseur.svg'
        break;

      case 'Mise aux normes du tableau électrique':
        this.recettePath = '../../../assets/icons/recette_tableau.svg'
        break;

      case 'VMC Double Flux':
        this.recettePath = '../../../assets/icons/recette_vmc.svg'
        break;
    }
  }

  closeModale() {
    this.dialogRef.closeAll();
  }
}

<h2>Rendez-vous à confirmer et positionner </h2>

<table class="table">
    <tbody>
        <tr>
            <td> <button [ngClass]="togglePosisionate  ? 'visible' : 'disabled'"  mat-raised-button color="primary" (click)=" showInactiveCases()" >À confirmer</button>
                </td><td> <button  *ngIf="currentRole =='Admin' " [ngClass]="toggleConfirmate ? 'visible' : 'disabled'"  mat-raised-button color="primary" (click)="ShowUnpositionateCases()">À positionner</button></td>
                <td>
                </td>
            </tr>
        <tr>
            <td>Nom client </td>
            <td *ngIf="currentRole !='Superviseur TMK' ">Statut Position</td>
            <td>Statut Confirmé</td>
        </tr>
        <tr *ngFor="let case of cases">
            <td>{{ case.customer.name}}</td>
            <td *ngIf="currentRole  =='Admin' && case.commercial==null">A positionner</td>
            <td *ngIf="currentRole  =='Admin' && case.commercial!=null">positionner</td>

            <td>{{case.stateTmk}}</td>

            <td>
                <button mat-raised-button color="primary"  [routerLink]="['/super-tmk-case-detail',case._id]">Affaires</button>
            </td>
        </tr>

        <button mat-raised-button color="primary" [routerLink]="['/tmk-cases']">Créer un nouveaux RDV</button>

    </tbody>
</table>
